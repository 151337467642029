import React, {Component} from 'react';
import ribbon1 from '../../assets/ribbon1.png';
import ribbon2 from '../../assets/ribbon2.png';
import ribbon3 from '../../assets/ribbon3.png';
import ribbon4 from '../../assets/ribbon4.png';
import ribbon5 from '../../assets/ribbon5.png';
import checkmark from '../../assets/checkmark.png';
import PrintOut from './PrintOut';

class Confirm extends Component{
    constructor(props){
        super(props)
        this.state = {
            printed: false,
        }
        this.handlePrinted = this.handlePrinted.bind(this);
    }
    handlePrinted(){
        this.setState({
            printed: true,
        })
    }
    print = () => {
        window.print();
    }
    render(){
        return(
            <div>
                <div className="success-div">
                    <img src={ribbon1} alt="" className="ribbon-1" />
                    <img src={ribbon2} alt="" className="ribbon-2" />
                    <img src={ribbon3} alt="" className="ribbon-3" />
                    <img src={ribbon4} alt="" className="ribbon-4" />
                    <img src={ribbon5} alt="" className="ribbon-5" />
                    <img src={checkmark} alt="" className="checkmark" />
                    <h4>Enrollment Successful!</h4>
                    <button onClick={this.print}>Print Form</button>
                    <p onClick={this.props.reset}>Close</p>
                </div>
                <PrintOut entry={this.props.entry}/>
            </div>
        )
    }
}

export default Confirm;