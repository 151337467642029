import React, {Component} from 'react';
import repeatCaseImg from '../../assets/repeat-case.svg';
import outbreakImg from '../../assets/outbreak.svg';
import nearSchoolImg from '../../assets/near-school.svg';
import repeatAddressImg from '../../assets/repeat-address.svg';

class AlertModalDetails extends Component{
    render(){
        const { alert, closeModal } = this.props;
        const { type, cases } = alert;
        return(
            <div className="alert-modal">
                {
                    type === "repeat_case" &&
                    <React.Fragment>
                        <h3>
                            <div 
                                className="icon"
                                style={{backgroundColor:'#EBE6F0'}}>
                                <img src={repeatCaseImg} alt=""/>
                            </div>
                            Repeat case detected
                        </h3>
                        <div className="modal-content">
                            <div className="top">
                                <div>Case #</div>
                                <div>Location</div>
                                <div>Date Recorded</div>
                            </div>
                            {
                                cases.map((item) => {
                                    const { id, lga, state } = item;
                                    return(
                                        <div className="item" key={id}>
                                            <div>{id}</div>
                                            <div>{lga}, {state}</div>
                                            <div></div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </React.Fragment>
                }
                {
                    type === "near_school" &&
                    <React.Fragment>
                        <h3>
                            <div 
                                className="icon"
                                style={{backgroundColor:'#FAEDFA'}}>
                                <img src={nearSchoolImg} alt=""/>
                            </div>
                            Cases near school area
                        </h3>
                        <div className="modal-content">
                            <div className="top">
                                <div>Case #</div>
                                <div>Location</div>
                                <div></div>
                            </div>
                            {
                                cases.map((item) => {
                                    const { id, lga, state } = item;
                                    return(
                                        <div className="item" key={id}>
                                            <div>{id}</div>
                                            <div>{lga}, {state}</div>
                                            <div></div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </React.Fragment>
                }
                {
                    type === "repeat_address" &&
                    <React.Fragment>
                        <h3>
                            <div 
                                className="icon"
                                style={{backgroundColor:'#FFF7E7'}}>
                                <img src={repeatAddressImg} alt=""/>
                            </div>
                            Repeat Address Detected
                        </h3>
                        <div className="modal-content">
                            <div className="top">
                                <div>Case #</div>
                                <div>Address</div>
                                <div>Location</div>
                            </div>
                            {
                                cases.map((item) => {
                                    const { id, address, lga, state } = item;
                                    return(
                                        <div className="item" key={id}>
                                            <div>{id}</div>
                                            <div>{address}</div>
                                            <div>{lga}, {state}</div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </React.Fragment>
                }
                {
                    type === "outbreaks" &&
                    <React.Fragment>
                        <h3>
                            <div 
                                className="icon"
                                style={{backgroundColor:'#FEE5E5'}}>
                                <img src={outbreakImg} alt=""/>
                            </div>
                            Potential Outbreak Warning
                        </h3>
                        <p><span>*</span> All cases on this list are within a 7km radius</p>
                        <div className="modal-content">
                            <div className="top">
                                <div>Case #</div>
                                <div>Address</div>
                                <div></div>
                            </div>
                            {
                                cases.map((item) => {
                                    const { id, lga, state } = item;
                                    return(
                                        <div className="item" key={id}>
                                            <div>{id}</div>
                                            <div>{lga}, {state}</div>
                                            <div></div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </React.Fragment>
                }
                <button 
                    className="close"
                    onClick={closeModal}>Close</button>
            </div>
        )
    }
}

export default AlertModalDetails;