import React, { Component } from 'react';
import moment from 'moment';
import { baseUrl } from './url';
import Modal from './modal/Modal';
import PlacesAutocomplete, {
    geocodeByAddress,
    getLatLng,
} from 'react-places-autocomplete';
import { GoogleApiWrapper } from 'google-maps-react';
import { classnames } from './helpers';
import gLogo from '../assets/google_logo.png';

class EditProfile extends Component {
    constructor(props) {
        super(props)
        this.state = {
            prefix: props.details.prefix,
            marital_status: props.details.marital_status,
            sex: props.details.sex,
            treatment_no: props.details.project_no,
            clinic_no: props.details.clinic_no,
            work_address: '',
            first_name: props.details.first_name,
            last_name: props.details.last_name,
            dob_day: moment(props.details.dob).format('D'),
            dob_month: moment(props.details.dob).format('MMMM'),
            dob_year: moment(props.details.dob).format('YYYY'),
            dob: moment(props.details.dob).format('DD-MM-YYYY'),
            enrollment_date: moment(props.details.date_enrolled).format('DD-MM-YYYY'),
            enrollment_day: moment(props.details.date_enrolled).format('D'),
            enrollment_month: moment(props.details.date_enrolled).format('MMMM'),
            enrollment_year: moment(props.details.date_enrolled).format('YYYY'),
            enroller: props.details.enroller,
            address: props.details.address,
            state: props.details.state,
            lga: props.details.lga,
            phone_no: props.details.phone_no,
            occupation: props.details.occupation,
            weight: props.details.weight,
            height: props.details.height,
            referral: props.details.referral,
            disable_button: true,
            states: [],
            lgas: [],
            days: [],
            months: [],
            years: [],
            show: false,
            errorModal: false,
            verifyModal: false,
            entry: {},
            response: true,
            errorMessage: '',
            latitude: props.details.position && props.details.position.coordinates[1],
            longitude: props.details.position && props.details.position.coordinates[0],
            isGeocoding: false,
        }
    }
    componentDidMount() {
        this.handleDateInput();
        this.handleStates();
        this.handleLgas();
    }
    hideErrorModal = () => {
        this.setState({ errorModal: false })
    }
    handleDateInput = () => {
        let days = [];
        let years = [];
        for (let i = 1; i <= 31; i++) {
            days.push(i);
        }
        let startYear = moment().format('YYYY');
        for (let i = startYear; i >= startYear - 100; i--) {
            years.push(i);
        }

        let months = ['January',
            'February',
            'March',
            'April',
            'May',
            'June',
            'July',
            'August',
            'September',
            'October',
            'November',
            'December'];
        this.setState({
            days,
            months,
            years
        })
    }
    handleStates = () => {
        let states = []
        fetch(`https://locationsng-api.herokuapp.com/api/v1/states`, {
            method: 'GET',
        })
            .then((response) => response.json())
            .then((responseJson) => {
                states = responseJson.map((state) => {
                    return state.name
                });
                this.setState({
                    states,
                })
            })
    }
    handleLgas = () => {
        fetch(`https://locationsng-api.herokuapp.com/api/v1/states/${this.state.state}/lgas`, {
            method: 'GET',
        })
            .then((response) => response.json())
            .then((responseJson) => {
                this.setState({
                    lgas: responseJson,
                    lgaResponse: true
                })
            })
    }
    handleChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value,
            treatment_noError: '',
            phone_noError: '',
        })
    }
    handleAddress = address => {
        this.setState({
            address,
            latitude: null,
            longitude: null,
            errorMessage: '',
        });
    };

    handleSelect = selected => {
        this.setState({ isGeocoding: true, address: selected });
        geocodeByAddress(selected)
            .then(res => getLatLng(res[0]))
            .then(({ lat, lng }) => {
                this.setState({
                    latitude: lat,
                    longitude: lng,
                    isGeocoding: false,
                });
            })
            .catch(error => {
                this.setState({ isGeocoding: false });
                console.log('error', error); // eslint-disable-line no-console
            });
    };

    handleError = (status, clearSuggestions) => {
        console.log('Error from Google Maps API', status); // eslint-disable-line no-console
        this.setState({ errorMessage: status }, () => {
            clearSuggestions();
        });
    };
    concatDate = () => {
        const { dob_day, dob_month, dob_year } = this.state;
        if (dob_day && dob_month && dob_year) {
            let monthNo = moment().month(dob_month).format("MM");
            let date = `${dob_day}-${monthNo}-${dob_year}`
            let dob = moment(date, 'D-MM-YYYY').format('DD-MM-YYYY');
            this.setState({
                dob: dob
            })
        }
    }
    concatEnrollDate = () => {
        const { enrollment_day, enrollment_month, enrollment_year } = this.state;
        if (enrollment_day && enrollment_month && enrollment_year) {
            let monthNo = moment().month(enrollment_month).format("MM");
            let date = `${enrollment_day}-${monthNo}-${enrollment_year}`
            let enrollDate = moment(date, 'D-MM-YYYY').format('DD-MM-YYYY');
            this.setState({
                enrollDate: enrollDate
            })
        }
    }
    onSubmit = (e) => {
        e.preventDefault();
        const {
            prefix,
            marital_status,
            sex,
            treatment_no,
            first_name,
            last_name,
            dob,
            address,
            state,
            lga,
            phone_no,
            occupation,
            referral,
            clinic_no,
            enrollment_date
        } = this.state;
        if (!prefix) {
            this.setState({
                prefixError: "This field should not be empty"
            })
        }
        if (!marital_status) {
            this.setState({
                marital_statusError: "This field should not be empty"
            })
        }
        if (!sex) {
            this.setState({
                sexError: "This field should not be empty"
            })
        }
        if (!treatment_no) {
            this.setState({
                treatment_noError: "This field should not be empty"
            })
        }
        if (!first_name) {
            this.setState({
                first_nameError: "This field should not be empty"
            })
        }
        if (!last_name) {
            this.setState({
                last_nameError: "This field should not be empty"
            })
        }
        if (!dob) {
            this.setState({
                dobError: "This field should not be empty"
            })
        }
        if (!address) {
            this.setState({
                addressError: "This field should not be empty"
            })
        }
        if (!state) {
            this.setState({
                stateError: "This field should not be empty"
            })
        }
        if (!lga) {
            this.setState({
                lgaError: "This field should not be empty"
            })
        }
        if (!phone_no) {
            this.setState({
                phone_noError: "This field should not be empty"
            })
        }
        if (!occupation) {
            this.setState({
                occupationError: "This field should not be empty"
            })
        }
        if (!referral) {
            this.setState({
                referralError: "This field should not be empty"
            })
        }
        // if (!enroller) {
        //     this.setState({
        //         enrollerError: "This field should not be empty"
        //     })
        // }
        if (!clinic_no) {
            this.setState({
                clinic_noError: "This field should not be empty"
            })
        }
        if (!enrollment_date) {
            this.setState({
                enrollment_dateError: "This field should not be empty"
            })
        }
        if (prefix && marital_status && sex && treatment_no && first_name && last_name && dob && address && state && lga && phone_no && occupation && referral && clinic_no && enrollment_date) {
            this.updateDetails();
        } else {
            this.setState({
                detailsError: "Check form fields for errors"
            })
        }
    }
    updateDetails = () => {
        this.setState({
            entry: this.state,
            response: false,
        })
        let id = localStorage.getItem('enrollee_id');
        const token = localStorage.getItem('access_token');
        let retries = 0;
        fetch(`${baseUrl}/recruiter/recruit/${id}`, {
            method: 'PUT',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': token
            },
            body: JSON.stringify({
                "prefix": this.state.prefix,
                "first_name": this.state.first_name,
                "last_name": this.state.last_name,
                "phone_no": this.state.phone_no,
                "sex": this.state.sex,
                "dob": this.state.dob,
                "marital_status": this.state.marital_status,
                "address": this.state.address,
                "work_address": this.state.work_address,
                "lga": this.state.lga,
                "state": this.state.state,
                "weight": this.state.weight,
                "height": this.state.height,
                "occupation": this.state.occupation,
                "enrollment_officer": this.state.enroller,
                "clinic_no": this.state.clinic_no,
                "referral": this.state.referral,
                "treatment_no": `NMC${this.state.treatment_no}`,
                "lat": this.state.latitude,
                "lng": this.state.longitude,
                "date_enrolled": this.state.enrollDate,
            })
        })
        .then((response) => {
            const auth = response.headers.get('Authorization');
            localStorage.setItem('access_token', auth);
            return response.json();
        })
        .then((responseJson) => {
            this.setState({
                response: true,
            })
            if (responseJson.hasOwnProperty('_meta')) {
                if (responseJson._meta._status === 202) {
                    this.props.showDetails();
                }
            }
            if (responseJson.hasOwnProperty('errors')) {
                if (responseJson.errors.treatment_no) {
                    this.setState({
                        treatment_noError: responseJson.errors.treatment_no[0],
                    })
                }
                if (responseJson.errors.phone_no) {
                    this.setState({
                        phone_noError: responseJson.errors.phone_no[0],
                    })
                    if (responseJson.errors.phone_no[0] === "Something went wrong, please contact administrator." || responseJson.errors.phone_no[0] === "The phone number entered either does not exist or is invalid.") {
                        this.setState({
                            verifyModal: true
                        })
                    }
                }
            }
        })
        .catch((error) => {
            if (error === 401 && retries < 1) {
                localStorage.getItem('access_token')
                    .then(value => {
                        fetch(`${baseUrl}/token/refresh`, {
                            headers: {
                                'Accept': 'application/json',
                                'Authorization': value
                            }
                        }).then(response => {
                            retries += 1;
                            if (response.status === 200) {
                                localStorage.setItem('access_token', response.headers.get('Authorization'));
                            }
                        })
                    })
            }
        })
    }
    render() {
        const { details } = this.props;
        const {
            states,
            lgas,
            days,
            months,
            years,
        } = this.state;
        let stateOption;
        if (states.length > 0) {
            stateOption = states.map((state, index) =>
                <option key={index}>{state === 'Federal Capital Territory' ? 'Abuja' : state}</option>
            )
        }
        let lgaOption;
        if (lgas.length > 0) {
            lgaOption = lgas.map((lga, index) =>
                <option key={index}>{lga}</option>
            )
        }
        let dayOption = days.map((day, index) =>
            <option key={index}>{day}</option>
        )
        let monthOption = months.map((month, index) =>
            <option key={index}>{month}</option>
        )
        let yearOption = years.map((year, index) =>
            <option key={index}>{year}</option>
        )
        return (
            <div className="details edit">
                {/* {
                    !response &&
                    <div className="loader">
                        <i className="fa fa-spinner fa-pulse fa-fw" />
                    </div>
                } */}
                <form onSubmit={this.onSubmit}>
                    {
                        Object.keys(details).length > 0 &&
                        <table>
                            <tbody>
                                <tr>
                                    <td>
                                        <h4>Project Number</h4>
                                    </td>
                                    <td>
                                        {/* <input className={this.state.treatment_noError ? "error" : ""} type="text" name="treatment_no" value={this.state.treatment_no} onChange={this.handleChange} /> */}
                                        <input type="text" value={this.state.treatment_no} disabled />
                                        <span>{this.state.treatment_noError}</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <h4>Clinic Number</h4>
                                    </td>
                                    <td>
                                        <input className={this.state.clinic_noError ? "error" : ""} type="text" name="clinic_no" value={this.state.clinic_no} onChange={this.handleChange} />
                                        {/* <input type="text" value={this.state.clinic_no} disabled /> */}
                                        <span>{this.state.clinic_noError}</span>
                                    </td>
                                </tr>
                                {/* <tr>
                                    <td>
                                        <h4>Enrollment Officer</h4>
                                    </td>
                                    <td>
                                        <input className={this.state.enrollerError ? "error" : ""} type="text" name="enroller" value={this.state.enroller} onChange={this.handleChange} />
                                        <span>{this.state.enrollerError}</span>
                                    </td>
                                </tr> */}
                                <tr>
                                    <td>
                                        <h4>Title</h4>
                                    </td>
                                    <td>
                                        <select className={this.state.prefixError ? "error" : ""} name="prefix" value={this.state.prefix} onChange={this.handleChange}>
                                            <option value="mr">Mr</option>
                                            <option value="mrs">Mrs</option>
                                            <option value="dr">Dr</option>
                                            <option value="miss">Miss</option>
                                        </select>
                                        <span>{this.state.prefixError}</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <h4>First Name</h4>
                                    </td>
                                    <td>
                                        <input className={this.state.first_nameError ? "error" : ""} type="text" name="first_name" value={this.state.first_name} onChange={this.handleChange} />
                                        <span>{this.state.first_nameError}</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <h4>Last Name</h4>
                                    </td>
                                    <td>
                                        <input className={this.state.last_nameError ? "error" : ""} type="text" name="last_name" value={this.state.last_name} onChange={this.handleChange} />
                                        <span>{this.state.last_nameError}</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <h4>Sex</h4>
                                    </td>
                                    <td>
                                        <select className={this.state.sexError ? "error" : ""} name="sex" value={this.state.sex} onChange={this.handleChange}>
                                            <option value="male">male</option>
                                            <option value="female">female</option>
                                        </select>
                                        <span>{this.state.sexError}</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <h4>Date of Birth</h4>
                                    </td>
                                    <td>
                                        <div className="flex-div">
                                            <select className={this.state.dobError ? "error" : ""} name="dob_day" value={this.state.dob_day} onChange={this.handleChange} onBlur={this.concatDate}>
                                                {dayOption}
                                            </select>
                                            <select className={this.state.dobError ? "error" : ""} name="dob_month" value={this.state.dob_month} onChange={this.handleChange} onBlur={this.concatDate}>
                                                {monthOption}
                                            </select>
                                            <select className={this.state.dobError ? "error" : ""} name="dob_year" value={this.state.dob_year} onChange={this.handleChange} onBlur={this.concatDate}>
                                                {yearOption}
                                            </select>
                                        </div>
                                        <span>{this.state.dobError}</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <h4>Marital Status</h4>
                                    </td>
                                    <td>
                                        <select className={this.state.marital_statusError ? "error" : ""} name="marital_status" value={this.state.marital_status} onChange={this.handleChange}>
                                            <option value="single">Single</option>
                                            <option value="married">Married</option>
                                            <option value="divorced">Divorced</option>
                                            <option value="separated">Separated</option>
                                            <option value="widowed">Widowed</option>
                                        </select>
                                        <span>{this.state.marital_statusError}</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <h4>Address</h4>
                                    </td>
                                    <td>
                                        <PlacesAutocomplete
                                            onChange={this.handleAddress}
                                            value={this.state.address}
                                            onSelect={this.handleSelect}
                                            onError={this.handleError}
                                            shouldFetchSuggestions={this.state.address.length > 2}
                                            className={this.state.addressError ? "error" : ""}
                                        >
                                            {({ getInputProps, suggestions, getSuggestionItemProps }) => {
                                                return (
                                                    <div>
                                                            <input {...getInputProps({
                                                                    type:"text",
                                                                    name: 'address'
                                                                })}/>
                                                            <span>{this.state.addressError}</span>
                                                        {
                                                            suggestions.length > 0 && (
                                                                <div className="autocomplete">
                                                                    {suggestions.map(suggestion => {
                                                                        const className = classnames('autocomplete-item', {
                                                                            'autocomplete-item--active': suggestion.active,
                                                                        });
                                                                        return (
                                                                            /* eslint-disable react/jsx-key */
                                                                            <div
                                                                                {...getSuggestionItemProps(suggestion, { className })}
                                                                            >
                                                                                <i className="fa fa-map-marker" />
                                                                                <strong>
                                                                                    {suggestion.formattedSuggestion.mainText}
                                                                                </strong>{' '}
                                                                                <small>
                                                                                    {suggestion.formattedSuggestion.secondaryText}
                                                                                </small>
                                                                            </div>
                                                                        );
                                                                        /* eslint-enable react/jsx-key */
                                                                    })}
                                                                    <div className="bottom">
                                                                        <div>
                                                                            <img
                                                                                src={gLogo}
                                                                                alt=""
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            )}
                                                    </div>
                                                );
                                            }}
                                        </PlacesAutocomplete>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <h4>State</h4>
                                    </td>
                                    <td>
                                        <select className={this.state.stateError ? "error" : ""} name="state" value={this.state.state} onChange={this.handleChange}>
                                            {stateOption}
                                        </select>
                                        <span>{this.state.stateError}</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <h4>LGA</h4>
                                    </td>
                                    <td>
                                        <select className={this.state.lgaError ? "error" : ""} name="lga" value={this.state.lga} onChange={this.handleChange}>
                                            {lgaOption}
                                        </select>
                                        <span>{this.state.lgaError}</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <h4>Occupation</h4>
                                    </td>
                                    <td>
                                        <input className={this.state.occupationError ? "error" : ""} type="text" name="occupation" value={this.state.occupation} onChange={this.handleChange} />
                                        <span>{this.state.occupationError}</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <h4>Phone Number</h4>
                                    </td>
                                    <td>
                                        <input className={this.state.phone_noError ? "error" : ""} type="text" name="phone_no" value={this.state.phone_no} onChange={this.handleChange} />
                                        <span>{this.state.phone_noError}</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <h4>Weight</h4>
                                    </td>
                                    <td>
                                        <input type="number" step="0.1" name="weight" value={this.state.weight} onChange={this.handleChange} />
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <h4>Height</h4>
                                    </td>
                                    <td>
                                        <input type="number" step="0.01" name="height" value={this.state.height} onChange={this.handleChange} />
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <h4>Referred from</h4>
                                    </td>
                                    <td>
                                        <select className={this.state.referralError ? "error" : ""} name="referral" value={this.state.referral} onChange={this.handleChange}>
                                            <option value="ART facility">ART facility</option>
                                            <option value="DOTS facility">DOTS facility</option>
                                            <option value="TB patient">TB patient</option>
                                            <option value="OPD">OPD</option>
                                            <option value="CV">CV</option>
                                            <option value="TS">TS</option>
                                            <option value="CTW">CTW</option>
                                            <option value="PPM Scheme 1 facility">PPM Scheme 1 facility</option>
                                            <option value="Others">Others</option>
                                        </select>
                                        <span>{this.state.referralError}</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <h4>Date Enrolled</h4>
                                    </td>
                                    <td>
                                        <div className="flex-div">
                                            <select className={this.state.enrollment_dateError ? "error" : ""} name="enrollment_day" value={this.state.enrollment_day} onChange={this.handleChange} onBlur={this.concatEnrollDate}>
                                                {dayOption}
                                            </select>
                                            <select className={this.state.enrollment_dateError ? "error" : ""} name="enrollment_month" value={this.state.enrollment_month} onChange={this.handleChange} onBlur={this.concatEnrollDate}>
                                                {monthOption}
                                            </select>
                                            <select className={this.state.enrollment_dateError ? "error" : ""} name="enrollment_year" value={this.state.enrollment_year} onChange={this.handleChange} onBlur={this.concatEnrollDate}>
                                                {yearOption}
                                            </select>
                                        </div>
                                        <span>{this.state.enrollment_dateError}</span>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    }
                    <div className="no-border"></div>
                    <div className="button-div">
                        <p><small>{this.state.detailsError}</small></p>
                        <button type="submit">
                            Save Information
                            {
                                !this.state.response &&
                                <i className="fa fa-spinner fa-pulse fa-fw"></i>
                            }
                        </button>
                    </div>
                </form>
                <Modal show={this.state.errorModal}>
                    <i className="fa fa-times-circle close-icon" onClick={this.hideErrorModal}></i>
                    <div className="form-error">
                        <h3>Enrollee's details counldn't be updated at this time, try again later.</h3>
                        <br />
                        <button onClick={this.hideErrorModal}>Close</button>
                    </div>
                </Modal>
            </div>
        )
    }
}

const Loader = () => {
  return (
    <div>
      <i className="fa fa-spinner fa-pulse fa-fw"></i>
    </div>
  )
}


export default
GoogleApiWrapper({
    apiKey: 'AIzaSyBSYFtIecZaXi9MBN65ZOrBB6DcreYFghg',
    LoadingContainer: Loader,
})(EditProfile);
