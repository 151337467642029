import React, { Component } from 'react';
import Header from '../Header';
// import hospital from '../../assets/hospital.png';
// import dot from '../../assets/dot.png';
import logo from '../../assets/nimcure-logo.png';
import MapContainer from '../MapContainer';
import Details from '../ProfileDetails';
import { baseUrl } from '../url';
import EditProfile from '../EditProfile';
import Modal from '../modal/Modal';

class ChangeStatus extends Component {
    constructor(props){
        super(props)
        this.state = {
            status: '',
            code: '',
            id: 0,
            response: true
        }
    }
    static getDerivedStateFromProps(nextProps, prevState) {
        return {
          id: nextProps.id,
        };
    }
    handleCode = (e) => {
        this.setState({
            code: e.target.value,
            error: ''
        })
    }
    handleStatus = (e) => {
        this.setState({
            status: e.target.value,
            error: ''
        })
    }
    handleSubmit = (e) => {
        this.setState({
            response: false
        })
        e.preventDefault();
        let retries = 0;
        const token = localStorage.getItem('access_token');
        const {id, status, code} = this.state;
        fetch(`${baseUrl}/recruiter/recruit/status/${id}`,{
            method: 'PUT',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': token
            },
            body: JSON.stringify({
                'code': code,
                'status': status
            })
        })
        .then((response) => {
            if(response.status === 403){
                this.setState({
                    error: "You are not allowed to perform this action."
                })
            }
            const auth = response.headers.get('Authorization');
            localStorage.setItem('access_token', auth);
            return response.json();
        })
        .then(responseJson => {
            this.setState({
                response: true
            })
            if (responseJson.hasOwnProperty('_meta')){
                if(responseJson._meta._status === 202){
                    this.props.hideModal();
                }
            }
            if (responseJson.hasOwnProperty('errors')){
                if(responseJson.errors.status){
                    this.setState({
                        error: responseJson.errors.status[0],
                    })
                }
                if(responseJson.errors.status){
                    this.setState({
                        error: responseJson.errors.status[0],
                    })
                }
            }
        })
        .catch((error) => {
            if (error === 401 && retries < 1) {
                localStorage.getItem('access_token')
                    .then(value => {
                        fetch(`${baseUrl}/token/refresh`, {
                            headers: {
                                'Accept': 'application/json',
                                'Authorization': value
                            }
                        }).then(response => {
                            retries += 1;
                            if (response.status === 200) {
                                localStorage.setItem('access_token', response.headers.get('Authorization'));
                            }
                        })
                    })
            }
        })
    }
    render() {
        const {code} = this.state;
        return (
            <React.Fragment>
                <div className="change-status">
                <h3>Change Status</h3>
                <select name="status" value={this.state.status || this.props.status} onChange={this.handleStatus}>
                    {(this.props.status === "unverified") && <option value="null">Unverified</option>}
                    <option value="positive">Positive</option>
                    <option value="negative">Negative</option>
                </select>
                <h5>Enter Your 4 Digit Code</h5>
                <input type="password" maxLength="4" value={code} onChange={this.handleCode}/>
                <p>{this.state.error}</p>
                <button onClick={this.handleSubmit} disabled={code.length < 4? true : false}>
                    Change Status
                    {
                        !this.state.response &&
                        <i className="fa fa-spinner fa-pulse fa-fw"></i>
                    }
                </button>
                <button className="cancel" onClick={this.props.hideModal}><i className="fa fa-times"></i></button>
            </div>
            </React.Fragment>
        )
    }
}

class EnrolleeProfile extends Component {
    constructor() {
        super()
        this.state = {
            details: {},
            response: true,
            isMounted: true,
            name: '',
            edit: false,
            modal: false,
        }
    }
    componentDidMount() {
        this.fetchDetails();
    }
    componentWillUnmount() {
        this.setState({ isMounted: false })
    }
    fetchDetails = () => {
        if (this.state.isMounted) {
            this.setState({
                response: false
            })
        }
        let id = localStorage.getItem('enrollee_id');
        const token = localStorage.getItem('access_token');
        let retries = 0;
        fetch(`${baseUrl}/recruiter/recruit/${id}`, {
            method: 'GET',
            headers: {
                authorization: token
            }
        }).then((response) => {
            const auth = response.headers.get('Authorization');
            localStorage.setItem('access_token', auth);
            return response.json();
        })
        .then((responseJson) => {
            if (this.state.isMounted) {
                this.setState({
                    details: responseJson.data,
                    response: true,
                })
            }
            if (Object.keys(this.state.details).length > 0) {
                let name = `${this.state.details.first_name} ${this.state.details.last_name}`
                this.getName(name)
            }
        })
        .catch((error) => {
            // if(error){
            window.addEventListener('online', () => {
                this.fetchDetails();
            });
            // }
            if (error === 401 && retries < 1) {
                localStorage.getItem('access_token')
                    .then(value => {
                        fetch(`${baseUrl}/token/refresh`, {
                            headers: {
                                'Accept': 'application/json',
                                'Authorization': value
                            }
                        }).then(response => {
                            retries += 1;
                            if (response.status === 200) {
                                localStorage.setItem('access_token', response.headers.get('Authorization'));
                                this.fetchDetails();
                            }
                        })
                    })
            }
        })
    }
    getName = (name) => {
        this.setState({
            name,
        })
    }
    print = () => {
        window.print();
    }
    editProfile = () => {
        this.setState({
            edit: true
        })
    }
    showDetails = () => {
        this.setState({
            edit: false
        }, () => { this.fetchDetails() })
    }
    showModal = () => {
        this.setState({
            modal: true
        })
    }
    hideModal = () => {
        this.setState({
            modal: false
        }, () => {this.fetchDetails()})
    }
    hide = () => {
        this.setState({
            modal: false
        })
    }
    render() {
        const { details, response, edit } = this.state;
        const role = localStorage.getItem('role');
        let statusClass = '';
        if (details.status === null){
            statusClass = 'unverified';
        }else(
            statusClass = details.status
        )
        return (
            <div>
                <Header />
                {
                    Object.keys(details).length !== 0 &&
                    <div className="enrollee-profile">
                        <div className="name-div">
                            <h4>{this.state.name}</h4>
                            <div className={`status ${statusClass}`}>{details.status === null ? 'Unverified' : details.status}</div>
                            {
                                role === 'enroller' && 
                                <button onClick={this.showModal}>Change status</button>
                            }
                        </div>
                        {role === 'admin' && <MapContainer title="Enrollee’s Location" height="523px" position={details.position} address={details.address}/>}
                        <div className="main">
                            <div className="left">
                                <div className="top">
                                    <img src={logo} alt="" className="logo" />
                                    <h5>Enrollee Information</h5>
                                </div>
                                {!edit && <Details getName={this.getName} details={details} response={response} />}
                                {edit && <EditProfile getName={this.getName} details={details} showDetails={this.showDetails} />}
                                {
                                    role === 'enroller' &&
                                    <div className="button-div">
                                        {!edit && <button onClick={this.editProfile}>Edit Information</button>}
                                    </div>
                                }
                            </div>
                            <div className="right">
                                <div className="closest">
                                    {/* <div className="item">
                                        <img src={dot} alt="" />
                                        <div>
                                            <h5>Closest DOT Centre</h5>
                                            <h6>6, Edmund crescent off Murtala Mohammed way, Yaba, Lagos, Nigeria.</h6>
                                        </div>
                                    </div>
                                    <div className="item">
                                        <img src={hospital} alt="" />
                                        <div>
                                            <h5>Closest Hospital</h5>
                                            <h6>Nigeria Army Reference Hospital, Yaba, Lagos State, Nigeria.</h6>
                                        </div>
                                    </div> */}
                                    <div className="">
                                        <div>
                                            <p>To keep a physical copy of enrollee's details, click the button below to print details.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="btn-div">
                                    <button onClick={this.print}>Print Enrollee’s Form</button>
                                </div>
                            </div>
                        </div>
                    </div>
                }
                {
                    Object.keys(details).length === 0 && 
                    <Loader/>
                }
                <Modal show={this.state.modal}>
                    {/* <i className="fa fa-times-circle close-icon" onClick={this.hide}></i> */}
                    {this.state.modal && <ChangeStatus id={details.id} status={details.status === null? 'unverified' : details.status} hideModal={this.hideModal}/>}
                </Modal>
            </div>
        )
    }
}

const Loader = () => (
    <div style={{
        width: "100%",
        display: "flex",
        justifyContent: "center",
        padding: "40px",
        marginTop: "100px"
    }}>
      <i style={{
          fontSize: "48px",
          color: "#6C63FF"
      }}
        className="fa fa-spinner fa-pulse fa-fw"></i>
    </div>
)

export default EnrolleeProfile;