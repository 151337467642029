import React, { Component } from 'react';
import { Map, Marker, GoogleApiWrapper, Circle, InfoWindow, Polyline } from 'google-maps-react';
import moment from 'moment';
import markerIcon from '../assets/marker.png';
import otherMarkers from '../assets/black-marker.png';
import smallMarker from '../assets/small-marker.png';
import schoolArea from '../assets/school_area.svg';
import repeatMarker from '../assets/repeat_address.svg';
import repeatAddress from '../assets/repeat-address.png';
import repeatCase from '../assets/repeat_case.svg';
import repeatCaseIcon from '../assets/repeat_case_icon.svg';
import point from '../assets/pulse-point.png';
import { baseUrl } from './url';
import PlacesAutocomplete, {
    geocodeByAddress,
    getLatLng,
} from 'react-places-autocomplete';
import { classnames } from './helpers';
import gLogo from '../assets/google_logo.png';

const MapKeys = () => {
    return(
        <React.Fragment>
            <h5>Key</h5>
            <div className="section">
                <div className="key">
                    <div className="marker"
                        style={{background: '#FFF7E7', color:'#FAA521'}}>
                        <i className="fa fa-map-marker"/>
                    </div>
                    <p>Repeat Address</p>
                </div>
                <div className="key">
                    <div className="marker"
                        style={{background: '#FAEDFA', color:'#CC4CCC'}}>
                        <i className="fa fa-map-marker"/>
                    </div>
                    <p>Case near a school</p>
                </div>
                <div className="key">
                    <div className="marker"
                        style={{background: '#EBE6F0', color:'#32096D'}}>
                        <i className="fa fa-map-marker"/>
                    </div>
                    <p>Repeat Case</p>
                </div>
                <div className="key">
                    <div className="marker"
                        style={{background: '#FEE5E5', color:'#F40000'}}>
                        <i className="fa fa-map-marker"/>
                    </div>
                    <p>Outbreak case</p>
                </div>
            </div>
        </React.Fragment>
    );
}

const Popup = (props) => {
    const { type, item } = props;
    return(
        <React.Fragment>
            {
                type === 'near_school' &&
                <div className="alert-popup">
                    <div className="popup-top">
                        <img src={schoolArea} alt=''/>
                        <p className="near-school">School Address!</p>
                    </div>
                    <p className="popup-text">Cases in this area are within 50m radius of this school address</p>
                </div>
            }
            {
                type === 'repeat_case' &&
                <div className="alert-popup">
                    <div className="popup-top">
                        <img src={repeatCaseIcon} alt=''/>
                        <p className="repeat-case">Repeat Case!</p>
                    </div>
                    <p className="popup-text">This person appears to have been enrolled in the system previously</p>
                </div>
            }
            {
                type === 'repeat_address' &&
                <div className="alert-popup">
                    <div className="popup-top">
                        <img src={repeatAddress} alt=''/>
                        <p className="repeat-address">Repeat Address!</p>
                    </div>
                    <p className="popup-text">{item.length} cases with this address occur</p>
                    <table>
                        <tbody>
                            <tr>
                                <th>Sex</th>
                                <th>Age</th>
                                <th>Status</th>
                            </tr>
                            {
                                item.map((subject) => {
                                    const { id, status, sex, dob } = subject;
                                    const age = moment().diff(dob, 'years');
                                    let formattedStatus = '';
                                    if (status === 'negative'){
                                        formattedStatus = 'Unconfirmed case'
                                    }
                                    if (status === 'positive'){
                                        formattedStatus = 'Confirmed case'
                                    }
                                    return(
                                        <tr key={id}>
                                            <td>{sex}</td>
                                            <td>{age}+</td>
                                            <td>{formattedStatus}</td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </table>
                </div>
            }
        </React.Fragment>
    )
}

class MapContainer extends Component {
    constructor(props) {
        super(props)
        this.state = {
            activeMarker: {},
            selectedPlace: {},
            showingInfoWindow: false,
            showFilter: false,
            markers: [],
            alertMarkers: [],
            filteredMarkers: [],
            radius: 0,
            address: this.props.address || '',
            latitude: '',
            longitude: '',
            bounds: {},
            popupType: '',
            popupItem: {},
            showPolyline: false,
            path: [],
        };
    }

    componentDidMount() {
        this.fetchMapData();
        this.fetchAlerts();
    }

    fetchMapData = () => {
        const token = localStorage.getItem('access_token');
        let retries = 0;
        fetch(`${baseUrl}/recruiter/recruit/map`, {
            method: 'GET',
            headers: {
                'Authorization': token
            }
        })
        .then((response) => {
            const auth = response.headers.get('Authorization');
            localStorage.setItem('access_token', auth);
            return response.json();
        })
        .then(responseJson => {
            if (responseJson._meta._status === 200) {
                let position = []
                responseJson.data.forEach(item => {
                    let lng = item.addr_position.coordinates[0];
                    let lat = item.addr_position.coordinates[1];
                    let result = {
                        id: item.id,
                        lng: lng,
                        lat: lat
                    }
                    position = [...position, result];
                })
                this.setState({
                    markers: position,
                }, () => {
                    this.createBounds(position);
                })
            }
        })
        .catch((error) => {
            if (error === 401 && retries < 1) {
                localStorage.getItem('access_token')
                    .then(value => {
                        fetch(`${baseUrl}/token/refresh`, {
                            headers: {
                                'Accept': 'application/json',
                                'Authorization': value
                            }
                        }).then(response => {
                            retries += 1;
                            if (response.status === 200) {
                                localStorage.setItem('access_token', response.headers.get('Authorization'));
                            }
                        })
                    })
            }
        })
    }

    createBounds = (markers) => {
        var bounds = new this.props.google.maps.LatLngBounds();  
        for (var i = 0; i < markers.length; i++) {
            bounds.extend(markers[i]);
        }
        this.setState({
            bounds,
        })
    }

    fetchAlerts = () => {
        const token = localStorage.getItem('access_token');
        let retries = 0;
        fetch(`${baseUrl}/recruiter/alerts`,{
            method: 'GET',
            headers: {
                authorization: token
            }
        }).then((response) => {
            const auth = response.headers.get('Authorization');
            localStorage.setItem('access_token', auth);
            return response.json();
        })
        .then((responseJson) => {
            console.log(responseJson.data);
            this.setState({
                alertMarkers: responseJson.data,
            })
        })
        .catch((error)=>{
            // if(error){
                window.addEventListener('online', () => {
                    this.fetchAlerts();
                });
            // }
            if (error === 401 && retries < 1){
                localStorage.getItem('access_token')
                .then(value => {
                    fetch(`${baseUrl}/token/refresh`,{
                        headers: {
                            'Accept': 'application/json',
                            'Authorization': value
                        }
                    }).then(response => {
                        retries += 1;
                        if(response.status === 200){
                            localStorage.setItem('access_token', response.headers.get('Authorization'));
                            this.fetchAlerts();
                        }
                    })
                })
            }
        })
    }

    onMarkerClick = (props, marker) => {
        this.setState({
            activeMarker: marker,
            selectedPlace: props,
            showingInfoWindow: true,
            popupType: marker.type,
            popupItem: marker.item,
        }, () => {
            if (marker.type === 'repeat_case') {
                this.drawPolyline(marker.item);
            }
        });
    }
    drawPolyline = (cases) => {
        let path = [];
        cases.forEach((each) => {
            const { position } = each;
            const { coordinates } = position;
            const coords = {
                lat: coordinates[1],
                lng: coordinates[0],
            }
            path = [...path, coords];
        })
        this.setState({
            showPolyline: true,
            path,
        })
    }
    onInfoWindowClose = () =>
        this.setState({
            activeMarker: null,
            showingInfoWindow: false,
            showPolyline: false,
            path: [],
        });

    toggleFilter = () => {
        this.setState({
            showFilter: !this.state.showFilter
        }, () => { this.removeRadius() })
    }
    removeRadius = () => {
        if (!this.state.showFilter) {
            this.setState({
                radius: 0,
                filteredMarkers: [],
                address: this.props.address || ''
            })
        }
    }
    handleAddress = address => {
        this.setState({
            address,
            latitude: null,
            longitude: null,
            errorMessage: '',
            radius: 0
        });
    };

    handleSelect = selected => {
        this.setState({ isGeocoding: true, address: selected });
        geocodeByAddress(selected)
            .then(res => getLatLng(res[0]))
            .then(({ lat, lng }) => {
                this.setState({
                    latitude: lat,
                    longitude: lng,
                    isGeocoding: false,
                });
            })
            .catch(error => {
                this.setState({ isGeocoding: false });
                console.log('error', error); // eslint-disable-line no-console
            });
    };

    handleError = (status, clearSuggestions) => {
        console.log('Error from Google Maps API', status); // eslint-disable-line no-console
        this.setState({ errorMessage: status }, () => {
            clearSuggestions();
        });
    };
    handleRadius = (e) => {
        let center = this.props.position || { coordinates: [this.state.longitude, this.state.latitude] };
        this.setState({
            radius: Number(e.target.value)
        }, () => { this.filterMap(center) })
    };
    recenterMap = (position) => {
        const map = this.refs.resultMap.map;
        const google = this.props.google;
        const maps = google.maps;

        let center = new maps.LatLng(position.coordinates[1], position.coordinates[0]);
        map.panTo(center);
        this.getCircleBounds();
    }
    getCircleBounds = () => {
        const { radius } = this.state;
        if (radius > 0) {
            const circle = this.refs.circle.circle;
            this.setState({
                circleBounds: circle.getBounds()
            })
        }
    }
    filterMap = (position) => {
        const { markers, radius } = this.state;
        // const nRadius = Number(radius);
        let filteredMarkers = []
        markers.forEach(marker => {
            var targetLat = marker.lat;
            var targetLng = marker.lng;

            var targetLoc = new this.props.google.maps.LatLng(targetLat, targetLng);

            var center = new this.props.google.maps.LatLng(position.coordinates[1], position.coordinates[0]);

            var distanceInkm = this.props.google.maps.geometry.spherical.computeDistanceBetween(center, targetLoc) / 1000;

            if (distanceInkm < radius) {
                filteredMarkers = [...filteredMarkers, marker]
            }
        })
        this.setState({
            filteredMarkers,
        }, () => { 
            this.recenterMap(position);
            this.createBounds(filteredMarkers);
        })
    }
    render() {
        const { position } = this.props;
        const { radius, circleBounds, address, alertMarkers, bounds, popupType, popupItem, showFilter, showPolyline, path } = this.state;
        let markers = [];

        if (radius > 0) {
            markers = this.state.filteredMarkers
        } else {
            markers = this.state.markers
        }

        const { title, height } = this.props;
        const lineSymbol = {
            path: 'M 0,-1 0,1',
            strokeColor: '#FA5914',
            strokeOpacity: 1,
            scale: 3
        };
        return (
            <div className="map-container">
                <div className="top">
                    <div className="section">
                        <h4>{title}</h4>
                        {
                            !showFilter &&
                            <div className="filter" onClick={this.toggleFilter}>
                                <i className="fa fa-tasks" />
                                <span>Show Filters</span>
                            </div>
                        }
                        {
                            showFilter &&
                            <div className="filter" onClick={this.toggleFilter}>
                                <i className="fa fa-close" />
                                <span>Hide Filters</span>
                            </div>
                        }
                    </div>
                    <div>
                        <MapKeys />
                    </div>
                </div>
                <div className="main">
                    {
                        showFilter &&
                        <div className="filter-div">
                            <div className="filter-input">
                                <label>Show Enrollees in</label>
                                <PlacesAutocomplete
                                    onChange={this.handleAddress}
                                    value={this.state.address}
                                    onSelect={this.handleSelect}
                                    onError={this.handleError}
                                    shouldFetchSuggestions={this.state.address.length > 2}
                                    className={this.state.addressError ? "error" : ""}
                                    disabled={position ? true : false}
                                >
                                    {({ getInputProps, suggestions, getSuggestionItemProps }) => {
                                        return (
                                            <div className="input-div">
                                                <input {...getInputProps({
                                                    type: "text",
                                                    name: 'address',
                                                    placeholder: "Enter Location"
                                                })} />
                                                <span>{this.state.addressError}</span>
                                                {
                                                    suggestions.length > 0 && (
                                                        <div className="autocomplete">
                                                            {suggestions.map(suggestion => {
                                                                const className = classnames('autocomplete-item', {
                                                                    'autocomplete-item--active': suggestion.active,
                                                                });
                                                                return (
                                                                    /* eslint-disable react/jsx-key */
                                                                    <div
                                                                        {...getSuggestionItemProps(suggestion, { className })}
                                                                    >
                                                                        <i className="fa fa-map-marker" />
                                                                        <strong>
                                                                            {suggestion.formattedSuggestion.mainText}
                                                                        </strong>{' '}
                                                                        <small>
                                                                            {suggestion.formattedSuggestion.secondaryText}
                                                                        </small>
                                                                    </div>
                                                                );
                                                                /* eslint-enable react/jsx-key */
                                                            })}
                                                            <div className="bottom">
                                                                <div>
                                                                    <img
                                                                        src={gLogo}
                                                                        alt=""
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )}
                                            </div>
                                        );
                                    }}
                                </PlacesAutocomplete>
                            </div>
                            <i className="fa fa-long-arrow-right" />
                            <div className="filter-input">
                                <label>within a radius of</label>
                                <select onChange={this.handleRadius} value={this.state.radius} disabled={address ? false : true}>
                                    <option value="0">Select Distance</option>
                                    <option value="0.05">50 m</option>
                                    <option value="0.1">100 m</option>
                                    <option value="0.25">250 m</option>
                                    <option value="0.5">500 m</option>
                                    <option value="1">1 km</option>
                                    <option value="2">2 km</option>
                                    <option value="5">5 km</option>
                                    <option value="10">10 km</option>
                                    <option value="15">15 km</option>
                                </select>
                            </div>
                        </div>
                    }
                    <div style={{ height: height, width: '100%', position: 'relative' }}>
                        {
                            !position &&
                            <Map
                                google={this.props.google}
                                ref="resultMap"
                                zoom={18}
                                style={{ height: height, width: '100%' }}
                                initialCenter={{
                                    lat: this.state.latitude || 6.465,
                                    lng: this.state.longitude || 3.406
                                }}
                                options={{
                                    styles: [
                                        {
                                            elementType: "labels",
                                            featureType: "poi",
                                            stylers: [{ visibility: "off", }],
                                        },
                                    ]
                                }}
                                streetViewControl= {false}
                                centerAroundCurrentLocation={false}
                                draggable={true}
                                bounds={this.state.radius > 0? circleBounds : bounds}
                            >
                                {
                                    markers.map((marker) => {
                                        return (
                                            <Marker
                                                key={marker.id}
                                                name={marker.name}
                                                position={{ lat: marker.lat, lng: marker.lng }}
                                                options={{ icon: `${smallMarker}` }} />
                                        )
                                    })
                                }
                                {
                                    alertMarkers.map((marker) => {
                                        const { type, cases } = marker;
                                        if (type === 'near_school') {
                                            return cases.map(item => {
                                                const { alert_location } = item;
                                                const { name, coords } = alert_location;
                                                const { coordinates } = coords;
                                                return ([
                                                    <Marker
                                                        key={item.id}
                                                        name={name}
                                                        position={{ lat: coordinates[1], lng: coordinates[0] }}
                                                        options={{ icon: `${schoolArea}` }}
                                                        item={item}
                                                        type={type}
                                                        onClick={this.onMarkerClick} />,
                                                    <Circle
                                                        radius={50}
                                                        ref="circle"
                                                        center={{lat: coordinates[1], lng: coordinates[0]}}
                                                        strokeWeight={0}
                                                        fillColor='#CC4CCC'
                                                        fillOpacity={0.1} />
                                                ])
                                            })
                                        }
                                        if (type === 'repeat_address') {
                                            const { position } = cases[0];
                                            const { coordinates } = position;
                                            return (
                                                <Marker
                                                    key={marker.id}
                                                    position={{ lat: coordinates[1], lng: coordinates[0] }}
                                                    options={{ icon: `${repeatMarker}` }}
                                                    item={cases}
                                                    type={type}
                                                    onClick={this.onMarkerClick}
                                                />
                                            )
                                        }
                                        if (type === 'repeat_case') {
                                            return cases.map((patient) => {
                                                const { position } = patient;
                                                const { coordinates } = position;
                                                return (
                                                    <Marker
                                                        key={patient.id}
                                                        position={{ lat: coordinates[1], lng: coordinates[0] }}
                                                        options={{ icon: `${repeatCase}` }}
                                                        item={cases}
                                                        type={type}
                                                        onClick={this.onMarkerClick}
                                                    /> 
                                                )
                                            })
                                        }
                                        return null
                                    })
                                }
                                {
                                    this.state.radius > 0 &&
                                    <Circle
                                        radius={this.state.radius * 1000}
                                        ref="circle"
                                        center={{lat: this.state.latitude, lng: this.state.longitude}}
                                        strokeColor='#FF0000'
                                        strokeOpacity={0.5}
                                        strokeWeight={1}
                                        fillColor='#FF0000'
                                        fillOpacity={0.1}
                                    />
                                }
                                {
                                    this.state.radius > 0 &&
                                    <Marker
                                    position={{ lat: this.state.latitude, lng: this.state.longitude }}
                                    options={{ icon: `${point}` }}
                                    onClick={this.onMarkerClick} />
                                }
                                {
                                    showPolyline &&
                                    <Polyline
                                        path={path}
                                        options={{
                                            strokeOpacity: 0,
                                            icons: [{
                                                icon: lineSymbol,
                                                offset: '0',
                                                repeat: '20px'
                                            }],
                                        }}
                                    />
                                }
                                <InfoWindow
                                    marker={this.state.activeMarker}
                                    visible={this.state.showingInfoWindow} 
                                    onClose={this.onInfoWindowClose}>
                                    <Popup item={popupItem} type={popupType}/>
                                </InfoWindow>
                            </Map>
                        }
                        {
                            position &&
                            <Map
                                google={this.props.google}
                                ref="resultMap"
                                zoom={18}
                                style={{ height: height, width: '100%' }}
                                center={{
                                    lat: position.coordinates[1],
                                    lng: position.coordinates[0]
                                }}
                                draggable={true}
                                bounds={bounds}
                            >
                                <Marker
                                    position={{ lat: position.coordinates[1], lng: position.coordinates[0] }}
                                    options={{ icon: `${markerIcon}` }}
                                    onClick={this.onMarkerClick} />
                                {
                                    this.state.radius > 0 &&
                                    markers.map((marker) => {
                                        return (
                                            <Marker
                                                key={marker.id}
                                                // name={marker.name}
                                                position={{ lat: marker.lat, lng: marker.lng }}
                                                options={{ icon: `${otherMarkers}` }}
                                                onClick={this.onMarkerClick} />
                                        )
                                    })
                                }
                            </Map>
                        }
                    </div>
                </div>
            </div>
        )
    }
}

const Loader = () => (
    <div style={{
        width: "100%",
        display: "flex",
        justifyContent: "center",
        padding: "25px"
    }}>
        <i style={{
            fontSize: "36px",
            color: "#6C63FF"
        }}
            className="fa fa-spinner fa-pulse fa-fw"></i>
    </div>
)

export default
    GoogleApiWrapper({
        apiKey: 'AIzaSyBSYFtIecZaXi9MBN65ZOrBB6DcreYFghg',
        LoadingContainer: Loader,
        libraries: ['geometry', 'places']
    })(MapContainer);