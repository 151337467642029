import React, { Component } from 'react';
import './App.css';
import { BrowserRouter as Router, Route } from "react-router-dom";
import Form from './components/Form/Form';
import Login from './components/Login';
import AdminDashboard from './components/AdminDashboard/Dashboard';
import Enrollees from './components/AdminDashboard/Enrollees';
import Alerts from './components/AdminDashboard/Alerts';
import EnrolleeProfile from './components/Dashboard/EnrolleeProfile';
import Profile from './components/Dashboard/Profile';
import { ProtectedRoute } from './components/Authetication';

const initialStatus = navigator.onLine;

class App extends Component {
  state = {
    online: true
  }
  componentDidMount(){
    this.setState({
      online: initialStatus
    })
    window.addEventListener('online', () => {
      this.setState({
        online: true
      })
    });
    
    window.addEventListener('offline', () => {
      this.setState({
        online: false
      })
    });
  }
  render() {
    const role = localStorage.getItem('role');
    return (
      <div className="App">
            <div className={this.state.online ? "no-display" : "network-status"}>
              <p>You are currently {this.state.online ? "online" : "offline"}</p>
            </div>
        <Router>
          <div>
            <Route exact path="/" component={Form} />
            <Route path="/login" component={Login} />
            <ProtectedRoute path="/dashboard" component={AdminDashboard} />
            {role === "admin" && <ProtectedRoute path="/enrollees" component={Enrollees} />}
            <ProtectedRoute path="/enrollee" component={EnrolleeProfile}/>
            <ProtectedRoute path="/profile" component={Profile}/>
            {role === "admin" && <ProtectedRoute path="/alerts" component={Alerts}/>}
          </div>
        </Router>
      </div>
    );
  }
}

export default App;
