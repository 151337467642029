import React, { Component } from 'react';
import logo from '../../assets/nimcure-logo.png';
import moment from 'moment';

class PrintOut extends Component{
    render(){
        const entry = this.props.entry;
        return(
            <div className="print-out">
                <div className="form-top">
                    <img src={logo} alt=""/>
                    <p>Enrollment Form</p>
                </div>
                {/* {
                    Object.keys(entry).length > 0 &&
                 */}
                <div className="form-body">
                    <table>
                        <tbody>
                            <tr>
                                <td>
                                    <h4>Project Number</h4>
                                </td>
                                <td>
                                    <h3>NMC{entry.treatment_no}</h3>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <h4>Clinic Number</h4>
                                </td>
                                <td>
                                    <h3>{entry.clinic_no}</h3>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <h4>Enrollment Officer</h4>
                                </td>
                                <td>
                                {/* .replace(/\b\w/g, l => l.toUpperCase()) */}
                                    <h3>{entry.code.replace(/\b\w/g, l => l.toUpperCase())}</h3>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <h4>Title</h4>
                                </td>
                                <td>
                                    <h3>{entry.title}</h3>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <h4>First Name</h4>
                                </td>
                                <td>
                                    <h3>{entry.first_name.replace(/\b\w/g, l => l.toUpperCase())}</h3>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <h4>Last Name</h4>
                                </td>
                                <td>
                                    <h3>{entry.last_name.replace(/\b\w/g, l => l.toUpperCase())}</h3>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <h4>Sex</h4>
                                </td>
                                <td>
                                    <h3>{entry.sex}</h3>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <h4>Date of Birth</h4>
                                </td>
                                <td>
                                    <h3>{moment(entry.dob, "DD-MM-YYYY").format('Do MMMM, YYYY')}</h3>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <h4>Marital Status</h4>
                                </td>
                                <td>
                                    <h3>{entry.marital_status.replace(/\b\w/g, l => l.toUpperCase())}</h3>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <h4>Address</h4>
                                </td>
                                <td>
                                    <h3>{entry.address.replace(/\b\w/g, l => l.toUpperCase())}</h3>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <h4>State</h4>
                                </td>
                                <td>
                                    <h3>{entry.state.replace(/\b\w/g, l => l.toUpperCase())}</h3>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <h4>LGA</h4>
                                </td>
                                <td>
                                    <h3>{entry.lga.replace(/\b\w/g, l => l.toUpperCase())}</h3>
                                </td>
                            </tr>
                            {
                                (entry.work_address !== '') &&
                            <tr>
                                <td>
                                    <h4>Work Address</h4>
                                </td>
                                <td>
                                    <h3>{entry.work_address.replace(/\b\w/g, l => l.toUpperCase())}</h3>
                                </td>
                            </tr>
                            }
                            <tr>
                                <td>
                                    <h4>Occupation</h4>
                                </td>
                                <td>
                                    <h3>{entry.occupation.replace(/\b\w/g, l => l.toUpperCase())}</h3>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <h4>Phone Number</h4>
                                </td>
                                <td>
                                    <h3>{entry.phone_no}</h3>
                                </td>
                            </tr>
                            {
                                (entry.weight !== '') &&
                            <tr>
                                <td>
                                    <h4>Weight</h4>
                                </td>
                                <td>
                                    <h3>{entry.weight} kg</h3>
                                </td>
                            </tr>
                            }
                            {
                                (entry.height !== '') &&
                            <tr>
                                <td>
                                    <h4>Height</h4>
                                </td>
                                <td>
                                    <h3>{entry.height} m</h3>
                                </td>
                            </tr>
                            }
                            <tr>
                                <td>
                                    <h4>Referred from</h4>
                                </td>
                                <td>
                                    <h3>{entry.referral.replace(/\b\w/g, l => l.toUpperCase())}</h3>
                                </td>
                            </tr>
                            {
                                (entry.enrollDate !== '') ?
                            <tr>
                                <td>
                                    <h4>Date Enrolled</h4>
                                </td>
                                <td>
                                    <h3>{moment(entry.enrollDate, "DD-MM-YYYY").format('Do MMMM, YYYY')}</h3>
                                </td>
                            </tr>
                            : (
                            <tr>
                                <td>
                                    <h4>Date Enrolled</h4>
                                </td>
                                <td>
                                    <h3>{moment().format('Do MMMM, YYYY')}</h3>
                                </td>
                            </tr>
                            )
                            }
                        </tbody>
                    </table>
                </div>
            </div>
        )
    }
}

export default PrintOut;