import React, {Component} from 'react';
import Header from '../Header';
import EnrolleesList from '../EnrolleesList';
import { Link } from 'react-router-dom';

class Enrollees extends Component{
    constructor(){
        super()
        this.state={
            dropdown: false,
            isMounted: true,
        }
        this.toggleDropdown = this.toggleDropdown.bind(this);
    }
    componentWillUnmount(){
        this.setState({ isMounted: false })
    }
    toggleDropdown(){
        this.setState({
            dropdown: !this.state.dropdown,
        })
    }
    render(){
        const role = localStorage.getItem('role');
        return(
            <div>
                <Header page="enrollees"/>
                <div className="enrollees">
                    <div className="top">
                        <h3>Enrollees</h3>
                        {
                            role === 'enroller' &&
                            <Link to="/" style={{ textDecoration: 'none' }}>
                                <button className="add-enrollee"><i className="fa fa-plus"/> Add Enrollee</button>
                            </Link>
                        }
                        {/* <button onClick={this.toggleDropdown}>Export List <i className="fa fa-caret-down"/></button>
                        <div className={`dropdown ${this.state.dropdown?'show':''}`}>
                            <h4>CSV</h4>
                            <h4>CSV</h4>
                        </div> */}
                    </div>
                    <div className="enrollees-container">
                        {this.state.isMounted && <EnrolleesList title="All Enrollees"/>}
                    </div>
                </div>
            </div>
        )
    }
}

export default Enrollees;