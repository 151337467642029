import React, {Component} from 'react';
import peopleImg from '../assets/people.png';
// import confirmedImg from '../assets/confirmed.svg';
// import maleFemaleImg from '../assets/male2female.svg'
import maleImg from '../assets/male.png';
import femaleImg from '../assets/female.png';
import {baseUrl} from './url';
import moment from 'moment';

class Overview extends Component{
    state = {
        overview:{},
        response: true,
        isMounted: true,
    }
    componentDidMount(){
        this.fetchSummary();
    }
    componentWillUnmount() {
        this.setState( { isMounted: false } )
    }
    fetchSummary = () =>{
        if(this.state.isMounted){
            this.setState({
                response: false
            })
        }
        
        const token = localStorage.getItem('access_token');
        let retries = 0;
        fetch(`${baseUrl}/recruiter/summary`,{
            method: 'GET',
            headers: {
                authorization: token
            }
        }).then((response) => {
            const auth = response.headers.get('Authorization');
            localStorage.setItem('access_token', auth);
            return response.json();
        })
        .then((responseJson) => {
            let lastLogin = moment(responseJson.data.last_login).format('ddd DD-MM-YYYY')
            localStorage.setItem('last_login', lastLogin);
            if( this.state.isMounted ) {
                this.setState({
                    overview: responseJson.data,
                    response: true,
                })
            }
        })
        .catch((error)=>{
            // if(error){
                window.addEventListener('online', () => {
                    this.fetchSummary();
                });
            // }
            if (error === 401 && retries < 1){
                localStorage.getItem('access_token')
                .then(value => {
                    fetch(`${baseUrl}/token/refresh`,{
                        headers: {
                            'Accept': 'application/json',
                            'Authorization': value
                        }
                    }).then(response => {
                        retries += 1;
                        if(response.status === 200){
                            localStorage.setItem('access_token', response.headers.get('Authorization'));
                            this.fetchSummary();
                        }
                    })
                })
            }
        })
    }
    render(){
        const {overview,response} = this.state;
        const role = localStorage.getItem('role');
        let male,
            female,
            total;

        if(Object.keys(overview).length){
            male = overview.distribution.male;
            female = overview.distribution.female;
            total = male + female;
        }
        let lastLogin = moment(overview.last_login).format('ddd DD-MM-YYYY')
        return(
            <div>
                {
                    !response && 
                    <div className="overview">
                        <div className="loader-div">
                            <i className="fa fa-spinner fa-pulse fa-fw"/>
                        </div>
                    </div>
                }
                {
                    response &&
                    <div className="overview">
                        <div className="left">
                            <h4>Welcome {overview.name}</h4>
                            <p>Here is a summary of {role === 'enroller'? 'your' : 'all'} enrollments</p>
                            <div className="enrollees-no">
                                <i className="fa fa-circle-o" />
                                <p><span>+12</span> Suspected cases today</p>
                            </div>
                            <div className="enrollees-no">
                                <i className="fa fa-circle-o" />
                                <p>Last Activity on {lastLogin}</p>
                            </div>
                        </div>
                        <div className="right">
                            <div className="metric">
                                <img src={peopleImg} alt="" />
                                <h4>{total}</h4>
                                <p>Total Enrollees</p>
                            </div>
                            <div className="metric">
                                <img src={maleImg} alt="" />
                                <h4>{male}</h4>
                                <p>Male Enrollees</p>
                            </div>
                            <div className="metric">
                                <img src={femaleImg} alt="" />
                                <h4>{female}</h4>
                                <p>Female Enrollees</p>
                            </div>
                        </div>
                    </div>
                }
            </div>
        )
    }
}

export default Overview;