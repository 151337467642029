import React, { Component } from 'react';
import moment from 'moment';

class ProfileDetails extends Component{
    render(){
        const {details} = this.props;
        const project_no = details.project_no || '';
        const clinic_no = details.clinic_no || '';
        const enrollment_officer = details.enrollment_officer || '';
        const prefix = details.prefix || '';
        const first_name = details.first_name || '';
        const last_name = details.last_name || '';
        const sex = details.sex || '';
        const dob = details.dob || '';
        const marital_status = details.marital_status || '';
        const address = details.address || '';
        const lga = details.lga || '';
        const state =  details.state || '';
        const occupation = details.occupation || '';
        const phone_no = details.phone_no || '';
        const weight =  details.weight || '';
        const height = details.height || '';
        const referral = details.referral || '';
        const date_enrolled = details.date_enrolled || '';
        return(
            <div className="details">
                {
                    Object.keys(details).length > 0 &&
                    <table>
                        <tbody>
                            {
                                (project_no.length !== 0) &&
                                <tr>
                                    <td>
                                        <h4>Project Number</h4>
                                    </td>
                                    <td>
                                        <h3>{`${details.project_no.replace(/\b\w/g, l => l.toUpperCase())}`}</h3>
                                    </td>
                                </tr>
                            }
                            {
                                (clinic_no.length !== 0) &&
                                <tr>
                                    <td>
                                        <h4>Clinic Number</h4>
                                    </td>
                                    <td>
                                        <h3>{details.clinic_no}</h3>
                                    </td>
                                </tr>
                            }
                            {
                                (enrollment_officer.length !== 0) &&
                                <tr>
                                    <td>
                                        <h4>Enrollment Officer</h4>
                                    </td>
                                    <td>
                                        <h3>{`${details.enrollment_officer.replace(/\b\w/g, l => l.toUpperCase())}`}</h3>
                                    </td>
                                </tr>
                            }
                            {
                                (prefix.length !== 0) &&
                                <tr>
                                    <td>
                                        <h4>Title</h4>
                                    </td>
                                    <td>
                                        <h3>{`${details.prefix.replace(/\b\w/g, l => l.toUpperCase())}`}</h3>
                                    </td>
                                </tr>
                            }
                            {
                                (first_name.length !== 0) &&
                                <tr>
                                    <td>
                                        <h4>First Name</h4>
                                    </td>
                                    <td>
                                        <h3>{`${details.first_name.replace(/\b\w/g, l => l.toUpperCase())}`}</h3>
                                    </td>
                                </tr>
                            }
                            {
                                (last_name.length !== 0) &&
                                <tr>
                                    <td>
                                        <h4>Last Name</h4>
                                    </td>
                                    <td>
                                        <h3>{`${details.last_name.replace(/\b\w/g, l => l.toUpperCase())}`}</h3>
                                    </td>
                                </tr>
                            }
                            {
                                (sex.length !== 0) &&
                                <tr>
                                    <td>
                                        <h4>Sex</h4>
                                    </td>
                                    <td>
                                        <h3>{`${details.sex.replace(/\b\w/g, l => l.toUpperCase())}`}</h3>
                                    </td>
                                </tr>
                            }
                            {
                                (dob.length !== 0) &&
                                <tr>
                                    <td>
                                        <h4>Date of Birth</h4>
                                    </td>
                                    <td>
                                        <h3>{moment(details.dob).format('DD-MM-YYYY')}</h3>
                                    </td>
                                </tr>
                            }
                            {
                                (marital_status.length !== 0) &&
                                <tr>
                                    <td>
                                        <h4>Marital Status</h4>
                                    </td>
                                    <td>
                                        <h3>{`${details.marital_status.replace(/\b\w/g, l => l.toUpperCase())}`}</h3>
                                    </td>
                                </tr>
                            }
                            {
                                (address.length !== 0) &&
                                <tr>
                                    <td>
                                        <h4>Address</h4>
                                    </td>
                                    <td>
                                        <h3>{`${details.address.replace(/\b\w/g, l => l.toUpperCase())}`}</h3>
                                    </td>
                                </tr>
                            }
                            {
                                (state.length !== 0) &&
                                <tr>
                                    <td>
                                        <h4>State</h4>
                                    </td>
                                    <td>
                                        <h3>{`${details.state.replace(/\b\w/g, l => l.toUpperCase())}`}</h3>
                                    </td>
                                </tr>
                            }
                            {
                                (lga.length !== 0) &&
                                <tr>
                                    <td>
                                        <h4>LGA</h4>
                                    </td>
                                    <td>
                                        <h3>{`${details.lga.replace(/\b\w/g, l => l.toUpperCase())}`}</h3>
                                    </td>
                                </tr>
                            }
                            {
                                (occupation.length !== 0) &&
                                <tr>
                                    <td>
                                        <h4>Occupation</h4>
                                    </td>
                                    <td>
                                        <h3>{`${details.occupation.replace(/\b\w/g, l => l.toUpperCase())}`}</h3>
                                    </td>
                                </tr>
                            }
                            {
                                (phone_no.length !== 0) &&
                                <tr>
                                    <td>
                                        <h4>Phone Number</h4>
                                    </td>
                                    <td>
                                        <h3>{details.phone_no}</h3>
                                    </td>
                                </tr>
                            }
                            {
                                (weight.length !== 0 || weight !== 0) &&
                                <tr>
                                    <td>
                                        <h4>Weight</h4>
                                    </td>
                                    <td>
                                        <h3>{details.weight} kg</h3>
                                    </td>
                                </tr>
                            }
                            {
                                (height.length !== 0 || height !== 0) &&
                                <tr>
                                    <td>
                                        <h4>Height</h4>
                                    </td>
                                    <td>
                                        <h3>{details.height} m</h3>
                                    </td>
                                </tr>
                            }
                            {
                                (referral.length !== 0) &&
                                <tr>
                                    <td>
                                        <h4>Referred from</h4>
                                    </td>
                                    <td>
                                        <h3>{`${details.referral.replace(/\b\w/g, l => l.toUpperCase())}`}</h3>
                                    </td>
                                </tr>
                            }
                            {
                                (date_enrolled.length !== 0) &&
                                <tr>
                                    <td>
                                        <h4>Date Enrolled</h4>
                                    </td>
                                    <td>
                                        <h3>{moment(details.date_enrolled).format('DD-MM-YYYY')}</h3>
                                    </td>
                                </tr>
                            }
                        </tbody>
                    </table>
                }
                <div className="no-border"></div>    
            </div>
        )
    }
}

export default ProfileDetails;
