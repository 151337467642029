import React, {Component} from 'react';
import logo from '../../assets/nimcure-logo.png';
import gLogo from '../../assets/google_logo.png';
import wavy from '../../assets/wavy.png';
import moment from 'moment';
import Modal from '../modal/Modal';
import Confirm from './Confirm';
import PlacesAutocomplete, {
    geocodeByAddress,
    getLatLng,
  } from 'react-places-autocomplete';
import {GoogleApiWrapper} from 'google-maps-react';
import { classnames } from '../helpers';
import {baseUrl} from '../url';

const state_lgas = require('../Data/lga-data.json');

const initialState = {
    title: '',
    marital_status: '',
    currentDiv: '',
    sex: '',
    treatment_no: '',
    clinic_no: '',
    code: '',
    enroller: '',
    work_address: '',
    first_name: '',
    last_name: '',
    dob_day: '',
    dob_month: '',
    dob_year: '',
    dob: '',
    enrollment_date: '',
    enrollDate: '',
    enrollment_day: '',
    enrollment_month: '',
    enrollment_year: '',
    address: '',
    state: '',
    lga: '',
    phone_no: '',
    email: '',
    occupation: '',
    weight: '',
    height: '',
    referral: '',
    history:'',
    children_no: '',
    disable_button: true,
    states: [],
    lgas: [],
    days: [],
    months: [],
    years: [],
    enrollmentYears: [],
    show: false,
    errorModal: false,
    verifyModal: false,
    entry: {},
    noResponse: false,
    errorMessage: '',
    latitude: null,
    longitude: null,
    isGeocoding: false,
    nearSchool: false,
    nearSchoolResults: [],
}
class Form extends Component{
    constructor(){
        super()
        this.state= {
            ...initialState
        }

        this.placesDiv = React.createRef();
    }
    componentDidMount(){
        this.handleDateInput();
        this.handleStates();
    }
    handleDateInput = () => {
        let days = [];
        let years = [];
        let enrollmentYears =[];

        for(let i = 1; i <= 31; i++){
            days.push(i);
        }
        let startYear = moment().format('YYYY');
        for(let i = startYear; i >= startYear-100; i--){
            years.push(i);
        }

        for(let i = startYear; i >= 2018; i--){
            enrollmentYears.push(i);
        }

        let months = [ 'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December' ];
        this.setState({
            days,
            months,
            years,
            enrollmentYears
        })
    }
    showModal = () => {
        this.setState({ show: true });
    }
    hideModal = () => {
        this.setState({ show: false }, this.props.toggle);
    }
    hideErrorModal = () => {
        this.setState({errorModal: false})
    }
    hideVerifyModal = () => {
        this.setState({verifyModal: false})
    }
    handleTitle = (value) => {
        this.setState({
            title: value,
            currentDiv: 'title'
        })
    }
    handleMaritalStatus = (value) => {
        this.setState({
            marital_status: value,
            currentDiv: 'marital'
        })
    }
    handleDiv = (value) => {
        this.setState({
            currentDiv: value
        })
        if (value === 'state'){
            this.handleStates()
        }
        if (value === 'lga'){
            this.handleLgas()
        }
    }
    handleSex = (value) => {
        this.setState({
            sex: value,
            currentDiv: 'sex'
        })
    }
    handleAddress = address => {
        this.setState({
            address,
            latitude: null,
            longitude: null,
            errorMessage: '',
        });
    };
    
    handleSelect = selected => {
        this.setState({ isGeocoding: true, address: selected });
        geocodeByAddress(selected)
        .then(res => getLatLng(res[0]))
        .then(({ lat, lng }) => {
            this.setState({
                latitude: lat,
                longitude: lng,
                isGeocoding: false,
            }, () => {
                this.getSchools(lat, lng);
            });
        })
        .catch(error => {
            this.setState({ isGeocoding: false });
            console.log('error', error); // eslint-disable-line no-console
        });
    };
    
    handleError = (status, clearSuggestions) => {
        console.log('Error from Google Maps API', status); // eslint-disable-line no-console
        this.setState({ errorMessage: status }, () => {
            clearSuggestions();
        });
    };
    
    handleStates = () => {
        let states = [];
        state_lgas.forEach(item => {
            const { state } = item;
            const { name } = state;
            states = [...states, name];
        });
        this.setState({
            states,
        })
    }
    handleLgas = () => {
        const { state } = this.state;
        const result = state_lgas.filter(item => state === item.state.name);
        const resultState = result[0].state;
        const { locals } = resultState;
        let lgas = [];
        locals.forEach(local => {
            const { name } = local;
            lgas = [...lgas, name];
        });
        this.setState({
            lgas,
        })
    }
    handleChange = (e) => {
        this.setState({ 
            [e.target.name]: e.target.value,
            treatment_noError: '',
            phone_noError: '',
            enrollment_officerError: '',
        }, this.disableButton)
    }
    disableButton = () => {
        const {
            title, 
            marital_status,
            sex,
            treatment_no,
            first_name,
            last_name,
            dob,
            address,
            state,
            lga,
            phone_no,
            occupation,
            referral,
            code,
            clinic_no
        } = this.state;
        if(title && marital_status && sex && treatment_no && first_name && last_name && dob && address && state && lga && phone_no && occupation && referral && code && clinic_no){
            this.setState({
                disable_button: false
            })
        }else{
            this.setState({
                disable_button: true
            })
        }
    }
    concatDate = () => {
        const {dob_day, dob_month,dob_year} = this.state;
        if(dob_day && dob_month && dob_year){
            let monthNo = moment().month(dob_month).format("MM");
            let date = `${dob_day}-${monthNo}-${dob_year}`
            let dob = moment(date, 'D-MM-YYYY').format('DD-MM-YYYY');
            this.setState({
                dob: dob
            })
        }
    }
    concatEnrollDate = () => {
        const {enrollment_day, enrollment_month, enrollment_year} = this.state;
        if(enrollment_day && enrollment_month && enrollment_year){
            let monthNo = moment().month(enrollment_month).format("MM");
            let date = `${enrollment_day}-${monthNo}-${enrollment_year}`
            let enrollDate = moment(date, 'D-MM-YYYY').format('DD-MM-YYYY');
            this.setState({
                enrollDate: enrollDate
            })
        }
    }
    handleReset = () => {
        this.setState(initialState, this.handleDateInput);
        window.scrollTo(0, 0);
    }
    getSchools = (lat, lng) => {
        const center = {lat, lng};
        const radius = 50;
        const placesDiv = this.placesDiv.current;
        const service = new this.props.google.maps.places.PlacesService(placesDiv);
        const request = {
            location: center,
            radius,
            type: ['school']
        };
        service.nearbySearch(request, (results, status) => {
            if (status === this.props.google.maps.places.PlacesServiceStatus.OK) {
               this.setState({
                   nearSchoolResults: results,
                   nearSchool: true,
               })
            }
        })
    }
    createAlert = (id) => {
        const { nearSchoolResults } = this.state;
        let locations = [];

        nearSchoolResults.map(result => {
            const lat = result.geometry.location.lat();
            const lng = result.geometry.location.lng();
            const geoLocation = {
                name: result.name,
                coords: {   
                    lat,
                    lng,
                },
                cases: [
                    id
                ]
            }
            locations = [...locations, geoLocation]
            return geoLocation;
        });

        let nearSchool = {
            type: 'near_school',
            locations,
        };

        fetch(`${baseUrl}/recruiter/alerts`,{
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(nearSchool)
        })
        .then((response) => {
            return response.json();
        })
        .then((responseJson) => {
            return responseJson;
        })
    }

    handleSubmit = (e) => {
        e.preventDefault();
        this.setState({
            entry: this.state,
            noResponse: true,
        })
        let retries = 0;
        fetch(`${baseUrl}/recruiter/recruit`,{
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                "prefix": this.state.title,
                "first_name": this.state.first_name,
                "last_name": this.state.last_name,
                "phone_no": this.state.phone_no,
                "sex": this.state.sex,
                "dob": this.state.dob,
                "marital_status": this.state.marital_status,
                "address": this.state.address,
                "work_address": this.state.work_address,
                "lga": this.state.lga,
                "state": this.state.state,
                "weight": this.state.weight,
                "height": this.state.height,
                "occupation": this.state.occupation,
                "email": this.state.email,
                // "no_of_children": this.state.no_of_children,
                // "history_of_intake": this.state.history_of_intake,
                "enrollment_officer": this.state.enroller,
                "clinic_no":  this.state.clinic_no,
                "referral": this.state.referral,
                "treatment_no": `NMC${this.state.treatment_no}`,
                "lat": this.state.latitude,
                "lng": this.state.longitude,
                "date_enrolled": this.state.enrollDate,
                "recruiter_code": this.state.code,
            })
        })
        .then((response) => {
            if(response.status === 403){
                this.setState({
                    error: "You are not allowed to perform this action.",
                    errorModal: true,
                })
            }
            return response.json();
        })
        .then((responseJson) => {
            console.log(responseJson);
            const { nearSchool } = this.state;
            this.setState({
                noResponse: false,
            })
            if (responseJson.hasOwnProperty('_meta')){
                if(responseJson._meta._status === 200){
                    this.setState({
                        show: true,
                    },() => {
                        if (nearSchool) {
                            this.createAlert(responseJson.case_id)
                        }
                    })
                }
            }
            if (responseJson.hasOwnProperty('errors')){
                if(responseJson.errors.treatment_no){
                    this.setState({
                        treatment_noError: responseJson.errors.treatment_no[0],
                    })
                }
                if(responseJson.errors.enrollment_officer){
                    this.setState({
                        enrollment_officerError: responseJson.errors.enrollment_officer[0],
                    })
                }
                if(responseJson.errors.clinic_no){
                    this.setState({
                        clinic_noError: responseJson.errors.clinic_no[0],
                    })
                }
                if(responseJson.errors.phone_no){
                    this.setState({
                        phone_noError: responseJson.errors.phone_no[0],
                    })
                    if(responseJson.errors.phone_no[0] === "Something went wrong, please contact administrator." || responseJson.errors.phone_no[0] === "The phone number entered either does not exist or is invalid."){
                        this.setState({
                            verifyModal: true
                        })
                    }
                }
            }
        })
        .catch((error)=>{
            if (error === 401 && retries < 1){
                localStorage.getItem('access_token')
                .then(value => {
                    fetch(`${baseUrl}/token/refresh`,{
                        headers: {
                            'Accept': 'application/json',
                            'Authorization': value
                        }
                    }).then(response => {
                        retries += 1;
                        if(response.status === 200){
                            localStorage.setItem('access_token', response.headers.get('Authorization'));
                        }
                        else{
                            this.setState({
                                errorModal: true,
                                noResponse: false,
                                error: "Something went wrong, please try again later"
                            })
                        }
                    })
                })
            }
            else{
                this.setState({
                    errorModal: true,
                    noResponse: false,
                    error: "Something went wrong, please try again later"
                })
            }
        })
    }
    render(){
        const {
            title, 
            marital_status, 
            currentDiv, 
            sex,
            states,
            lgas,
            days,
            months,
            years,
            enrollmentYears,
            address
        } = this.state;
        let stateOption;
        if(states.length > 0){
            stateOption = states.map((state, index) => 
                <option key={index}>{state === 'Federal Capital Territory'?'Abuja':state}</option>
            )
        }
        let lgaOption;
        if(lgas.length > 0){
            lgaOption = lgas.map((lga, index) => 
                <option key={index}>{lga}</option>
            )
        }
        let dayOption = days.map((day, index) => 
            <option key={index}>{day}</option>
        )
        let monthOption = months.map((month, index) => 
            <option key={index}>{month}</option>
        )
        let yearOption = years.map((year, index) => 
            <option key={index}>{year}</option>
        )
        let enrollmentYearOption = enrollmentYears.map((year, index) => 
            <option key={index}>{year}</option>
        )
        return(
            <div className="enrollment-form">
                <div className="form-header">
                    <img src={logo} alt=""/>
                </div>
                <div className="waves">
                    <img src={wavy} alt=""/>
                </div>
                <div className="title-div">
                    <h3>Enrollment Form</h3>
                </div>
                <div className="form-container">
                    <form onSubmit={this.handleSubmit}>
                        <div className="form">
                            <div className={`input-div ${currentDiv === 'treatment-number'?'current-div':''} ${this.state.treatment_noError?'input-error':''}`}>
                                <label>
                                    Project Number
                                    <div className="input-span">
                                        <div>
                                            NMC
                                        </div>
                                        <input type="number" name="treatment_no" value={this.state.treatment_no} onFocus={() => this.handleDiv('treatment-number')} onChange={this.handleChange}/>
                                    </div>
                                </label>
                                <span className="error-span">{this.state.treatment_noError}</span>
                            </div>
                            <div className={`input-div ${currentDiv === 'clinic-number'?'current-div':''} ${this.state.clinic_noError?'input-error':''}`}>
                                <label>
                                    Clinic Number
                                    <input type="text" name="clinic_no" value={this.state.clinic_no} onFocus={() => this.handleDiv('clinic-number')} onChange={this.handleChange}/>
                                </label>
                                <span className="error-span">{this.state.clinic_noError}</span>
                            </div>
                            <div className={`input-div ${currentDiv === 'title'?'current-div':''}`}>
                                <label>
                                    Title
                                    <div className="input-options">
                                        <div className={`option ${title==="mr"? 'selected':''}`} 
                                            onClick={() => this.handleTitle("mr")}>
                                            Mr <i className="fa fa-check-circle"/>
                                        </div>
                                        <div className={`option ${title==="mrs"? 'selected':''}`}  
                                            onClick={() => this.handleTitle("mrs")}>
                                            Mrs <i className="fa fa-check-circle"/>
                                        </div>
                                        <div className={`option ${title==="dr"? 'selected':''}`}  
                                            onClick={() => this.handleTitle("dr")}>
                                            Dr <i className="fa fa-check-circle"/>
                                        </div>
                                        <div className={`option ${title==="ms"? 'selected':''}`}  
                                            onClick={() => this.handleTitle("ms")}>
                                            Miss <i className="fa fa-check-circle"/>
                                        </div>
                                    </div>
                                </label>
                            </div>
                            <div className={`input-div ${currentDiv === 'first-name'?'current-div':''}`}>
                                <label>
                                    First Name
                                    <input type="text" name="first_name" placeholder="Enter First Name" value={this.state.first_name} onFocus={() => this.handleDiv('first-name')} onChange={this.handleChange}/>
                                </label>
                            </div>
                            <div className={`input-div ${currentDiv === 'last-name'?'current-div':''}`}>
                                <label>
                                    Last Name
                                    <input type="text" name="last_name" placeholder="Enter Last Name" value={this.state.last_name} onFocus={() => this.handleDiv('last-name')} onChange={this.handleChange}/>
                                </label>
                            </div>
                            <div className={`input-div ${currentDiv === 'sex'?'current-div':''}`}>
                                <label>
                                    Sex
                                    <div className="input-options">
                                        <div className={`option ${sex==="Male"? 'selected':''}`} 
                                            onClick={() => this.handleSex("Male")}>
                                            Male <i className="fa fa-check-circle"/>
                                        </div>
                                        <div className={`option ${sex==="Female"? 'selected':''}`}  
                                            onClick={() => this.handleSex("Female")}>
                                            Female <i className="fa fa-check-circle"/>
                                        </div>
                                    </div>
                                </label>
                            </div>
                            <div className={`input-div ${currentDiv === 'dob'?'current-div':''}`}>
                                <label>
                                    Date of Birth
                                </label>
                                <div className="input-options">
                                    <select name="dob_day" onClick={() => this.handleDiv('dob')} onChange={this.handleChange} onBlur={this.concatDate}>
                                        <option>Day</option>
                                        {dayOption}
                                    </select>
                                    <select name="dob_month" onClick={() => this.handleDiv('dob')} onChange={this.handleChange} onBlur={this.concatDate}>
                                        <option>Month</option>
                                        {monthOption}
                                    </select>
                                    <select name="dob_year" onClick={() => this.handleDiv('dob')} onChange={this.handleChange} onBlur={this.concatDate}>
                                        <option>Year</option>
                                        {yearOption}
                                    </select>
                                </div>
                            </div>
                            <div className={`input-div ${currentDiv === 'marital'?'current-div':''}`}>
                                <label>
                                    Marital Status
                                    <div className="input-options">
                                        <div className={`option ${marital_status==="single"? 'selected':''}`} 
                                            onClick={() => this.handleMaritalStatus("single")}>
                                            Single <i className="fa fa-check-circle"/>
                                        </div>
                                        <div className={`option ${marital_status==="married"? 'selected':''}`} 
                                            onClick={() => this.handleMaritalStatus("married")}>
                                            Married <i className="fa fa-check-circle"/>
                                        </div>
                                        <div className={`option ${marital_status==="divorced"? 'selected':''}`} 
                                            onClick={() => this.handleMaritalStatus("divorced")}>
                                            Divorced <i className="fa fa-check-circle"/>
                                        </div>
                                        <div className={`option ${marital_status==="seperated"? 'selected':''}`} 
                                            onClick={() => this.handleMaritalStatus("seperated")}>
                                            Separated <i className="fa fa-check-circle"/>
                                        </div>
                                        <div className={`option ${marital_status==="widowed"? 'selected':''}`} 
                                            onClick={() => this.handleMaritalStatus("widowed")}>
                                            Widowed <i className="fa fa-check-circle"/>
                                        </div>
                                    </div>
                                </label>
                            </div>
                            <PlacesAutocomplete
                                onChange={this.handleAddress}
                                value={address}
                                onSelect={this.handleSelect}
                                onError={this.handleError}
                                shouldFetchSuggestions={address.length > 2}
                            >
                                {({ getInputProps, suggestions, getSuggestionItemProps }) => {
                                    return (
                                        <div className={`input-div ${currentDiv === 'address'?'current-div':''}`}>
                                            <label>
                                                Home Address
                                                <textarea
                                                    {...getInputProps({
                                                        placeholder: 'Enter Address',
                                                        row:'6',
                                                        name:'address'
                                                    })}
                                                    onFocus={() => this.handleDiv('address')}
                                                />
                                            </label>
                                            {
                                                suggestions.length > 0 && (
                                                    <div className="autocomplete">
                                                        {suggestions.map(suggestion => {
                                                            const className = classnames('autocomplete-item', {
                                                                'autocomplete-item--active': suggestion.active,
                                                            });
                                                            return (
                                                                /* eslint-disable react/jsx-key */
                                                                <div
                                                                    {...getSuggestionItemProps(suggestion, { className })}
                                                                >
                                                                    <i className="fa fa-map-marker"/>
                                                                    <strong>
                                                                        {suggestion.formattedSuggestion.mainText}
                                                                    </strong>{' '}
                                                                    <small>
                                                                        {suggestion.formattedSuggestion.secondaryText}
                                                                    </small>
                                                                </div>
                                                            );
                                                            /* eslint-enable react/jsx-key */
                                                        })}
                                                        <div className="bottom">
                                                            <div>
                                                                <img
                                                                    src={gLogo}
                                                                    alt=""
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                        </div>
                                    );
                                }}
                            </PlacesAutocomplete>
                            {/* {errorMessage.length > 0 && ( */}
                                <div>
                                    <div className={`input-div ${currentDiv === 'state' ? 'current-div' : ''}`}>
                                        <label>
                                            State
                                    <select name="state" onFocus={() => this.handleDiv('state')} onChange={this.handleChange}>
                                                <option>Select State&#160;&#160;&#160;</option>
                                                {stateOption}
                                            </select>
                                        </label>
                                    </div>
                                    <div className={`input-div ${currentDiv === 'lga' ? 'current-div' : ''}`}>
                                        <label>
                                            Local Government Area
                                    <select name="lga" onFocus={() => this.handleDiv('lga')} onChange={this.handleChange}>
                                                <option>Select LGA&#160;&#160;&#160;</option>
                                                {lgaOption}
                                            </select>
                                        </label>
                                    </div>
                                </div>
                            {/* )} */}
                            <div className={`input-div ${currentDiv === 'work-address'?'current-div':''}`}>
                                    <label>
                                        Work Address(optional)
                                        <textarea row="6" name="work_address" placeholder="Enter Work Address" value={this.state.work_address} onFocus={() => this.handleDiv('work-address')} onChange={this.handleChange}/>
                                    </label>
                                </div>

                            <div className={`input-div ${currentDiv === 'phone'?'current-div':''} ${this.state.phone_noError?'input-error':''}`}>
                                <label>
                                    Phone Number
                                    <input type="number" name="phone_no" placeholder="080..." value={this.state.phone_no} onFocus={() => this.handleDiv('phone')} onChange={this.handleChange}/>
                                </label>
                                <span className="error-span">{this.state.phone_noError}</span>
                            </div>
                            <div className={`input-div ${currentDiv === 'email'?'current-div':''} ${this.state.phone_noError?'input-error':''}`}>
                                <label>
                                    Email (Optional)
                                    <input type="email" name="email" placeholder="Enter email address" value={this.state.email} onFocus={() => this.handleDiv('email')} onChange={this.handleChange}/>
                                </label>
                                <span className="error-span">{this.state.phone_noError}</span>
                            </div>
                            <div className={`input-div ${currentDiv === 'occupation'?'current-div':''}`}>
                                <label>
                                    Occupation
                                    <input type="text" name="occupation" placeholder="Enter Occupation" value={this.state.occupation} onFocus={() => this.handleDiv('occupation')} onChange={this.handleChange}/>
                                </label>
                            </div>
                            <div className={`input-div ${currentDiv === 'weight'?'current-div':''}`}>
                                <label>
                                    Weight (Optional)
                                    <input type="number" step="0.1" name="weight" placeholder="Enter Weight" value={this.state.weight} onFocus={() => this.handleDiv('weight')} onChange={this.handleChange}/>
                                    <span className="metric-span">Kilogrammes</span>
                                </label>
                            </div>
                            <div className={`input-div ${currentDiv === 'height'?'current-div':''}`}>
                                <label>
                                    Height (Optional)
                                    <input type="number" name="height" step="0.01" placeholder="Enter Height" value={this.state.height} onFocus={() => this.handleDiv('height')} onChange={this.handleChange}/>
                                    <span className="metric-span">Metres</span>
                                </label>
                            </div>
                            <div className={`input-div ${currentDiv === 'enrollment_date'?'current-div':''}`}>
                                <label>
                                    Date of Enrollment (Optional, for previously registered patients)
                                </label>
                                <div className="input-options">
                                    <select name="enrollment_day" onClick={() => this.handleDiv('enrollment_date')} onChange={this.handleChange} onBlur={this.concatEnrollDate}>
                                        <option>Day</option>
                                        {dayOption}
                                    </select>
                                    <select name="enrollment_month" onClick={() => this.handleDiv('enrollment_date')} onChange={this.handleChange} onBlur={this.concatEnrollDate}>
                                        <option>Month</option>
                                        {monthOption}
                                    </select>
                                    <select name="enrollment_year" onClick={() => this.handleDiv('enrollment_date')} onChange={this.handleChange} onBlur={this.concatEnrollDate}>
                                        <option>Year</option>
                                        {enrollmentYearOption}
                                    </select>
                                </div>
                            </div>
                            <div className={`input-div ${currentDiv === 'referred'?'current-div':''}`}>
                                <label>
                                    Patient was referred by
                                    <select name="referral" value={this.state.referral} onFocus={() => this.handleDiv('referred')} onChange={this.handleChange}>
                                        <option>Select option&#160;&#160;&#160;</option>
                                        <option value="ART facility">ART facility</option>
                                        <option value="DOTS facility">DOTS facility</option>
                                        <option value="TB patient">TB patient</option>
                                        <option value="OPD">OPD</option>
                                        <option value="CV">CV</option>
                                        <option value="TS">TS</option>
                                        <option value="CTW">CTW</option>
                                        <option value="PPM Scheme 1 facility">PPM Scheme 1 facility</option>
                                        <option value="Others">Others</option>
                                    </select>
                                </label>
                            </div>
                            <div className={`input-div ${currentDiv === 'enroller'?'current-div':''}`}>
                                <label>
                                    Enrollment Officer
                                    <input type="text" name="enroller" value={this.state.enroller} onFocus={() => this.handleDiv('enroller')} onChange={this.handleChange}/>
                                </label>
                            </div>
                            <div className={`input-div ${currentDiv === 'code'?'current-div':''}`}>
                                <label>
                                    Officer's Code(4 digits)
                                    <input type="password" name="code" maxLength="4" value={this.state.code} onFocus={() => this.handleDiv('code')} onChange={this.handleChange}/>
                                </label>
                            </div>
                        </div>
                        <div className="submit-div">
                            <button type="submit" disabled={this.state.disable_button}>
                                Submit
                                {
                                    this.state.noResponse &&
                                    <i className="fa fa-spinner fa-pulse fa-fw"></i>
                                }
                            </button>
                            <div>
                                <p>{this.state.enrollment_officerError}</p>
                                <p>{this.state.treatment_noError}</p>
                                <p>{this.state.phone_noError}</p>
                                <p>{this.state.clinic_noError}</p>
                            </div>
                        </div>
                    </form>
                </div>
                <div ref={this.placesDiv}></div>
                <Modal show={this.state.show}>
                    {this.state.show && <Confirm close={this.hideModal} entry={this.state.entry} reset={this.handleReset} />}
                </Modal>
                <Modal show={this.state.errorModal}>
                    <i className="fa fa-times-circle close-icon" onClick={this.hideErrorModal}></i>
                    <div className="form-error">
                        <h3>{this.state.error}</h3>
                        <button onClick={this.hideErrorModal}>Close</button>
                    </div>
                </Modal>
                <Modal show={this.state.verifyModal}>
                    <i className="fa fa-times-circle close-icon" onClick={this.hideVerifyModal}></i>
                    <div className="form-error">
                        <h3>{this.state.phone_noError}</h3>
                        <button onClick={this.hideVerifyModal}>Close</button>
                    </div>
                </Modal>
            </div>
        )
    }
}

const Loader = () => (
    <div style={{
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems:'center',
        padding: "25px",
        height: '100vh'
    }}>
        <i style={{
            fontSize: "36px",
            color: "#6C63FF"
        }}
            className="fa fa-spinner fa-pulse fa-fw"></i>
    </div>
)

export default
GoogleApiWrapper({
    apiKey: 'AIzaSyBSYFtIecZaXi9MBN65ZOrBB6DcreYFghg',
    LoadingContainer: Loader,
})(Form);