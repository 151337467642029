import React, { Component } from 'react';
import Header from '../Header';
import EnrolleesList from '../EnrolleesList';
import DashboardAlerts from './DashboardAlerts';
import Overview from '../Overview';
import MapContainer from '../MapContainer';

class AdminDashboard extends Component {
    render() {
        const role = localStorage.getItem('role');
        return (
            <div>
                <Header page="dashboard" />
                <div className="dashboard">
                    <Overview />
                    {
                        role === 'admin' &&
                        <React.Fragment>
                            <DashboardAlerts />
                            <div className="enrollees-container">
                                <MapContainer title="All Suspected Cases" height="623px" />
                            </div>
                        </React.Fragment>
                    }
                    {
                        role === 'enroller' &&
                        <div className="enrollees-container">
                            <EnrolleesList title="Your Enrollees" />
                        </div>
                    }
                </div>
            </div>
        )
    }
}

export default AdminDashboard;