import React, {Component} from 'react';
import loginBg from '../assets/login_bg.png';
import logo from '../assets/nimcure-logo.png';
import {baseUrl} from './url';
import {Redirect} from 'react-router-dom';

const sectionStyle = {
    backgroundImage: `url(${loginBg})`
};

class Login extends Component{
    state = {
        username: '',
        password: '',
        usernameError: '',
        passwordError: '',
        error: '',
        login: false,
        response: true,
    }
    handleChange = (e) =>{
        this.setState({
            [e.target.name]: e.target.value,
            [e.target.name + 'Error']: '',
            error: ''
        })
    }
    handleSubmit = (e) => {
        e.preventDefault();
        const {username, password} = this.state;
        if(!username){
            this.setState({
                usernameError: "Field cannot be empty"
            })
        }
        if(!password){
            this.setState({
                passwordError: "Field cannot be empty"
            })
        }
        if(username && password){
            this.setState({
                response: false
            })
            fetch(`${baseUrl}/recruiter/login`,{
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    username: username,
                    password: password,
                })
            })
            .then(response => response.json())
            .then((responseJson) => {
                this.setState({
                    response: true
                })
                if (responseJson.hasOwnProperty('errors')){
                    if(responseJson.errors.username){
                        this.setState({
                            error: responseJson.errors.username[0]
                        })
                    }
                }else if( responseJson.hasOwnProperty('data')){
                    const token = responseJson.data.token;
                    const role =  responseJson.data.role;
                    localStorage.setItem('access_token', token);
                    localStorage.setItem('role', role);
                    this.setState({login:true})
                }
            })
        }
    }
    render(){
        const {username,password,usernameError,passwordError,error,login,response} = this.state;
        const { from } = this.props.location.state || { from: { pathname: '/' } }
        const token = localStorage.getItem('access_token'); 
        if ( token || login ) {
            return <Redirect to={from}/>;
        }   
        return(
            <div className="dashboard-login">
                <div className="left" style={sectionStyle}>
                    <img src={logo} alt="nimcure-logo"/>
                </div>
                <div className="right">
                    <div className="form">
                        <h4>Login to Enrollment Dashboard</h4>
                        <p>{error}</p>
                        <form>
                            <div className={`input-div ${usernameError?'error':''}`}>
                                <label>Email Address</label>
                                <input type="email" name="username" onChange={this.handleChange} value={username}/>
                                <span>{usernameError}</span>
                            </div>
                            <div className={`input-div ${passwordError?'error':''}`}>
                                <label>Password</label>
                                <input type="password" name="password" onChange={this.handleChange} value={password}/>
                                <span>{passwordError}</span>
                            </div>
                            <button onClick={this.handleSubmit}>
                                Login
                                {
                                    !response &&
                                    <i className="fa fa-spinner fa-pulse fa-fw"></i>
                                }
                            </button>
                        </form>
                    </div>
                </div>
            </div>
        )
    }
}

export default Login;