import React,{Component} from 'react';
import { withRouter } from 'react-router-dom';
import moment from 'moment';
import {baseUrl} from './url';

window.onload = function() {
    localStorage.removeItem('page_no')
}

class EnrolleesList extends Component{
    constructor(){
        super()
        this.state = {
            page: localStorage.getItem('page_no') || 1,
            last_page: 0,
            data: [],
            response: true,
            isMounted: true,
            order: '',
            sortBy: '',
            maritalStatus: '',
            sex: '',
            search: '',
        }
        this.myRef = null
        this.fetchData = this.fetchData.bind(this);
        this.onChange = this.onChange.bind(this);
        this.handlePrevious = this.handlePrevious.bind(this);
        this.handleNext = this.handleNext.bind(this);
    }
    componentDidMount(){
        this.fetchData(false);
    }
    componentWillUnmount() {
        this.setState( { isMounted: false } )
    }
    scrollToMyRef = () => {
        window.scrollTo({
            top:this.myRef.offsetTop, 
            behavior: "smooth" 
        })
    }
    fetchData(value){
        if(this.state.isMounted){
            this.setState({
                response: false
            })
        }
        let {page, maritalStatus, sex, search, sortBy, order} = this.state;
        if(page !== '' && this.state.isMounted){
            const token = localStorage.getItem('access_token');
            let retries = 0;
            fetch(`${baseUrl}/recruiter/recruit?page=${page}&marital_status=${maritalStatus}&sex=${sex}&search=${search}&${sortBy}=${order}`,{
                method: 'GET',
                headers:{
                    authorization: token
                }
            })
            .then((response) => {
                const auth = response.headers.get('Authorization');
                localStorage.setItem('access_token', auth);
                return response.json();
            })
            .then((responseJson) => {
                if( this.state.isMounted ) {
                    this.setState({
                        data: responseJson.data,
                        last_page: responseJson.meta.last_page,
                        response: true,
                    })
                }
                localStorage.setItem('page_no', responseJson.meta.current_page)
                if(value){
                    this.scrollToMyRef();
                }
            })
            .catch((error)=>{
                if(error && this.state.isMounted){
                    window.addEventListener('online', () => {
                        this.fetchData();
                    });
                }
                if (error === 401 && retries < 1){
                    localStorage.getItem('access_token')
                    .then(value => {
                        fetch(`${baseUrl}/token/refresh`,{
                            headers: {
                                'Accept': 'application/json',
                                'Authorization': value
                            }
                        }).then(response => {
                            retries += 1;
                            if(response.status === 200){
                                localStorage.setItem('access_token', response.headers.get('Authorization'));
                                this.fetchData();
                            }
                        })
                    })
                }
            })
        }
    }
    onChange(e){
        this.setState({
            page: e.target.value
        }, () => {
            if(this.state.page){this.fetchData(true)}
        })
    }
    handlePrevious(){
        let page = Number(this.state.page);
        if(page > 1){
            let newPage = page - 1;
            this.setState({
                page: newPage
            }, () => this.fetchData(true));
        }
    }
    handleMarital = (e) => {
        this.setState({
            maritalStatus: e.target.value,
            page: 1,
            order: ''
        },this.fetchData)
    }
    handleSex = (e) => {
        this.setState({
            sex: e.target.value,
            page: 1,
            order: ''
        },this.fetchData)
    }
    handleSearch = (e) =>{
        this.setState({
            search: e.target.value,
            page: 1,
            order: ''
        },this.fetchData)
    }
    handleNext(){
        let page = Number(this.state.page);
        let lastPage = Number(this.state.last_page);
        if(page < lastPage){
            let newPage = page + 1;
            this.setState({
                page: newPage
            }, () => this.fetchData(true))
        }
    }
    viewProfile = (id) => {
        localStorage.setItem('enrollee_id', id)
        this.props.history.push({
            pathname: '/enrollee',
        })
    }
    sortEntries = (value) => {
        localStorage.removeItem('page_no');
        let order = this.state.order;
        let sortBy =  this.state.sortBy;

        if(value !== sortBy){
            order = '';
            this.setState({
                order: ''
            })
        }
        if(order === '' || order === 'desc'){
            this.setState({
                page: 1,
                order: 'asc',
                sortBy: value,
            },this.fetchData)
        }
        if(order === 'asc'){
            this.setState({
                page: 1,
                order: 'desc',
                sortBy: value,
            },this.fetchData)
        }
    }
    render(){
        const {data,last_page,page,sortBy,order} = this.state;
        return(
            <div>
                <div className="title" ref={ (ref) => this.myRef=ref }>
                    <h4>{this.props.title}</h4>
                    <div className="input-div">
                        <input type="text" onChange={this.handleSearch} placeholder="Search first name, last name or LGA" />
                        <i className="fa fa-search"/>
                    </div>
                </div>
                <div className="list">
                    <table>
                        <tbody>
                            <tr>
                                <th>
                                    <div className="first">
                                        Clinic No
                                    </div>
                                </th>
                                <th>
                                    <div className="sort-div" onClick={() => this.sortEntries('last_name')}>
                                        Surname
                                        <div className="icon-div">
                                            <i className={`fa fa-caret-up ${(sortBy === 'last_name' && order === 'asc')? 'active':''}`}/>
                                            <i className={`fa fa-caret-down ${(sortBy === 'last_name' && order === 'desc')? 'active':''}`}/>
                                        </div>
                                    </div>
                                </th>
                                <th>
                                    <div className="sort-div" onClick={() => this.sortEntries('first_name')}>
                                        Name
                                        <div className="icon-div">
                                            <i className={`fa fa-caret-up ${(sortBy === 'first_name' && order === 'asc')? 'active':''}`}/>
                                            <i className={`fa fa-caret-down ${(sortBy === 'first_name' && order === 'desc')? 'active':''}`}/>
                                        </div>
                                    </div>
                                </th>
                                <th>
                                    <div>
                                        <select onChange={this.handleSex}>
                                            <option value="">Sex</option>
                                            <option value="male">Male</option>
                                            <option value="female">Female</option>
                                        </select>
                                        <i className="fa fa-caret-down"/>
                                    </div>
                                </th>
                                <th>   
                                    <div>
                                        <select onChange={this.handleMarital}>
                                            <option value="">Marital Status</option>
                                            <option value="married">Married</option>
                                            <option value="single">Single</option>
                                            <option value="divorced">Divorced</option>
                                            <option value="widowed">Widowed</option>
                                            <option value="separated">Separated</option>
                                        </select>
                                        <i className="fa fa-caret-down"/>
                                    </div>
                                </th>
                                <th>
                                    <div className="sort-div" onClick={() => this.sortEntries('lga')}>
                                        LGA
                                        <div className="icon-div">
                                            <i className={`fa fa-caret-up ${(sortBy === 'lga' && order === 'asc')? 'active':''}`}/>
                                            <i className={`fa fa-caret-down ${(sortBy === 'lga' && order === 'desc')? 'active':''}`}/>
                                        </div>
                                    </div>
                                </th>
                                <th>
                                    <div className="sort-div" onClick={() => this.sortEntries('date_enrolled')}>
                                        Date Enrolled
                                        <div className="icon-div">
                                            <i className={`fa fa-caret-up ${(sortBy === 'date_enrolled' && order === 'asc')? 'active':''}`}/>
                                            <i className={`fa fa-caret-down ${(sortBy === 'date_enrolled' && order === 'desc')? 'active':''}`}/>
                                        </div>
                                    </div>
                                </th>
                                <th></th>
                            </tr>
                            {
                                this.state.response &&
                                data.map((entry, index) => {
                                    return(
                                        <tr key={index}>
                                            <td>{entry.project_no}</td>
                                            <td>{entry.last_name}</td>
                                            <td>{entry.first_name}</td>
                                            <td>{entry.sex}</td>
                                            <td>{entry.marital_status}</td>
                                            <td>{entry.lga}</td>
                                            <td>{moment(entry.date_enrolled).format('DD-MM-YYYY')}</td>
                                            <td>
                                                <button onClick={()=>this.viewProfile(entry.id)}>View</button>
                                            </td>
                                        </tr>
                                    )
                                })
                            }
                            {
                                !this.state.response &&
                                <tr>
                                    <td className="loader" colSpan="7">
                                        <i className="fa fa-spinner fa-pulse fa-fw"/>
                                    </td>
                                </tr>
                            }
                        </tbody>
                    </table>
                    {/* <div className="btn-div">
                        <button>View All</button>
                    </div> */}
                    <div className="pagination-div">
                        <div className="entries">
                            Entries per page:
                            <p>{data.length}</p>
                            {/* <select>
                                <option>10</option>
                                <option>20</option>
                                <option>50</option>
                                <option>100</option>
                            </select> */}
                        </div>
                        <div className="pagination">
                            <button onClick={this.handlePrevious} disabled={page <= 1? true: false}>Previous</button>
                            <input type="number" value={page} onChange={this.onChange}/>
                            <p>of</p>
                            <div>{last_page}</div>
                            <button onClick={this.handleNext} disabled={page >= last_page? true: false}>Next</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default withRouter(EnrolleesList);
