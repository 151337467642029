import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import logo from '../assets/nimcure-logo.png';
import icon from '../assets/logo-icon.png';

class Header extends Component{
    render(){
        const pageProps = this.props.page;
        const role = localStorage.getItem('role');
        return(
            <div className="header">
                <img src={logo} alt="nimcure logo" className="desktop"/>
                <img src={icon} alt="nimcure logo" className="mobile"/>
                <div className="menu-options">
                    <Link to="/dashboard" style={{ textDecoration: 'none' }}>
                        <h5 className={pageProps === "dashboard"?'active':''}>Dashboard</h5>
                    </Link>
                    {
                        role === 'admin' &&
                        <Link to="/enrollees" style={{ textDecoration: 'none' }}>
                            <h5 className={pageProps === "enrollees"?'active':''}>Enrollees</h5>
                        </Link>
                    }
                    {/* {
                        role === 'admin' &&
                        <Link to="/alerts" style={{ textDecoration: 'none' }}>
                            <h5 className={pageProps === "alerts"?'active':''}>Alerts</h5>
                        </Link>
                    } */}
                </div>
                <Link to="/profile" style={{ textDecoration: 'none' }} className="last">
                    <div className="badge"><i className="fa fa-user-o"/></div>
                </Link>
            </div>
        )
    }
}

export default Header;