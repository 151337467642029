import React,{Component} from 'react';
import Header from '../Header';
import Modal from '../modal/Modal';
import { Redirect } from 'react-router-dom';

class ChangePassword extends Component{
    render(){
        return(
            <div className="change-password">
                <h4>Change Password</h4>
                <form>
                    <div className="input-div">
                        <label>Old Password</label>
                        <input type="password" />
                    </div>
                    <div className="input-div">
                        <label>New Password</label>
                        <input type="password" />
                    </div>
                    <div className="input-div">
                        <label>Confirm New Password</label>
                        <input type="password" />
                    </div>
                    <button>Change Password</button>
                </form>
            </div>
        )
    }
}
class Profile extends Component{
    constructor(){
        super()
        this.state = {
            passwordModal: false,
            logout: false,
        }
    }
    showPasswordModal = () => {
        this.setState({
            passwordModal: true
        })
    }
    hidePasswordModal = () => {
        this.setState({
            passwordModal: false
        })
    }
    handleLogout = () => {
        localStorage.clear();
        this.setState({
            logout: true
        })
    }
    render(){
        const { logout } = this.state;
        if (logout) {
            return <Redirect exact to='/dashboard'/>;
        } 
        let lastLogin = localStorage.getItem('last_login');
        return(
            <div>
                <Header/>
                <div className="officer-profile">
                    <div className="profile-main">
                        <div className="badge">
                            <i className="fa fa-user-o"/>
                        </div>
                        <div className="last-login">
                                <i className="fa fa-circle-o"/>
                                <p>Last Activity on {lastLogin}</p>
                        </div>
                        <button onClick={this.handleLogout}>Logout</button>
                        <button className="change-password-btn" onClick={this.showPasswordModal}>Change Password</button>
                    </div>
                    <Modal show={this.state.passwordModal}>
                        <i className="fa fa-times-circle close-icon" onClick={this.hidePasswordModal}></i>
                        <ChangePassword/>
                    </Modal>
                </div>
            </div>
        )
    }
}

export default Profile;