import React, { Component } from 'react';
import moment from 'moment';
import {baseUrl} from '../url';
import Modal from '../modal/Modal';
import AlertModalDetails from './AlertModalDetails';
import repeatCaseImg from '../../assets/repeat-case.svg';
import outbreakImg from '../../assets/outbreak.svg';
import nearSchoolImg from '../../assets/near-school.svg';
import repeatAddressImg from '../../assets/repeat-address.svg';

class Alerts extends Component {
    state = {
        currentTab: 'all',
        alerts: [],
        modal: false,
        modalData: {},
    }
    componentDidMount(){
        this.fetchAlerts();
    }
    fetchAlerts = () => {
        const token = localStorage.getItem('access_token');
        let retries = 0;
        fetch(`${baseUrl}/recruiter/alerts`,{
            method: 'GET',
            headers: {
                authorization: token
            }
        }).then((response) => {
            const auth = response.headers.get('Authorization');
            localStorage.setItem('access_token', auth);
            return response.json();
        })
        .then((responseJson) => {
            this.setState({
                alerts: responseJson.data,
            })
        })
        .catch((error)=>{
            // if(error){
                window.addEventListener('online', () => {
                    this.fetchAlerts();
                });
            // }
            if (error === 401 && retries < 1){
                localStorage.getItem('access_token')
                .then(value => {
                    fetch(`${baseUrl}/token/refresh`,{
                        headers: {
                            'Accept': 'application/json',
                            'Authorization': value
                        }
                    }).then(response => {
                        retries += 1;
                        if(response.status === 200){
                            localStorage.setItem('access_token', response.headers.get('Authorization'));
                            this.fetchSummary();
                        }
                    })
                })
            }
        })
    }
    handleTabs = (value) => {
        this.setState({
            currentTab: value
        })
    }
    showModal = (alert) => {
        this.setState({
            modal: true,
            modalData: alert,
        })
    }
    hideModal = () => {
        this.setState({
            modal: false,
        })
    }
    render() {
        const { currentTab, alerts, modal, modalData } = this.state;
        return (
            <div>
                {
                    alerts.length > 0 &&
                    <React.Fragment>
                        <div className="alerts">
                            <div className="main">
                                <div className="top">
                                    <h4>Alerts</h4>
                                    <div 
                                        className={`tab ${currentTab === 'all'?'active':''}`}
                                        onClick={() => this.handleTabs('all')}>
                                        All
                                    </div>
                                    {/* <div 
                                        className={`tab ${currentTab === 'outbreak'?'active':''}`}
                                        onClick={() => this.handleTabs('outbreak')}>
                                        Outbreak warnings
                                    </div>
                                    <div 
                                        className={`tab ${currentTab === 'repeat-cases'?'active':''}`}
                                        onClick={() => this.handleTabs('repeat-cases')}>
                                        Repeat cases
                                    </div>
                                    <div 
                                        className={`tab ${currentTab === 'repeat-address'?'active':''}`}
                                        onClick={() => this.handleTabs('repeat-address')}>
                                        Repeat addresses
                                    </div>
                                    <div 
                                        className={`tab ${currentTab === 'near-school'?'active':''}`}
                                        onClick={() => this.handleTabs('near-school')}>
                                        Cases near school areas
                                    </div> */}
                                </div>
                                <div className="alerts-content">
                                    <div className="date">This Week</div>
                                    {
                                        alerts.map(alert => {
                                            let number = alert.cases.length;
                                            if (alert.type === "repeat_case") {
                                                return (
                                                    <div className="alert" key={alert.id}>
                                                        <div 
                                                            className="icon"
                                                            style={{backgroundColor:'#EBE6F0'}}>
                                                            <img src={repeatCaseImg} alt=""/>
                                                        </div>
                                                        <div className="item">
                                                            <h6>Repeat Case detected</h6>
                                                            <h5>
                                                                {number} new repeated cases
                                                                <span><i className="fa fa-circle"/>{moment(alert.created_at).fromNow()}</span>
                                                                <span 
                                                                    className="view-link"
                                                                    onClick={() => this.showModal(alert)}>View</span>
                                                            </h5>
                                                        </div>
                                                    </div>
                                                )
                                            }
                                            if (alert.type === "near_school") {
                                                return (
                                                    <div className="alert" key={alert.id}>
                                                        <div 
                                                            className="icon"
                                                            style={{backgroundColor:'#FAEDFA'}}>
                                                            <img src={nearSchoolImg} alt=""/>
                                                        </div>
                                                        <div className="item">
                                                            <h6>Cases near school area</h6>
                                                            <h5>
                                                                {number} new cases near {alert.location_name}, Ikeja
                                                                <span><i className="fa fa-circle"/>{moment(alert.created_at).fromNow()}</span>
                                                                <span 
                                                                    className="view-link"
                                                                    onClick={() => this.showModal(alert)}>View</span>
                                                            </h5>
                                                        </div>
                                                    </div>
                                                );
                                            }
                                            if (alert.type === "repeat_address") {
                                                return (
                                                    <div className="alert" key={alert.id}>
                                                        <div 
                                                            className="icon"
                                                            style={{backgroundColor:'#FFF7E7'}}>
                                                            <img src={repeatAddressImg} alt=""/>
                                                        </div>
                                                        <div className="item">
                                                            <h6>Repeat Address Detected</h6>
                                                            <h5>
                                                                {number} repeat addresses found 
                                                                <span><i className="fa fa-circle"/>{moment(alert.created_at).fromNow()}</span>
                                                                <span 
                                                                    className="view-link"
                                                                    onClick={() => this.showModal(alert)}>View</span>
                                                            </h5>
                                                        </div>
                                                    </div>
                                                );
                                            }
                                            if (alert.type === "outbreaks") {
                                                return (
                                                    <div className="alert" key={alert.id}>
                                                        <div 
                                                            className="icon"
                                                            style={{backgroundColor:'#FEE5E5'}}>
                                                            <img src={outbreakImg} alt=""/>
                                                        </div>
                                                        <div className="item">
                                                            <h6>Potential Outbreak Warning</h6>
                                                            <h5>
                                                                {number} outbreak cases in {alert.location_name}
                                                                <span><i className="fa fa-circle"/>{moment(alert.created_at).fromNow()}</span>
                                                                <span 
                                                                    className="view-link"
                                                                    onClick={() => this.showModal(alert)}>View</span>
                                                            </h5>
                                                        </div>
                                                    </div>
                                                );
                                            }
                                            return null;
                                        })
                                    }
                                </div>
                            </div>
                        </div>
                        <Modal show={modal}>
                            <i className="fa fa-times-circle close-icon" onClick={this.hideModal}></i>
                            <AlertModalDetails alert={modalData} closeModal={this.hideModal}/>
                        </Modal> 
                    </React.Fragment>
                }
            </div>
        )
    }
}

export default Alerts;
