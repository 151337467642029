import React, { Component } from 'react';
import Header from '../Header';

class Alerts extends Component {
    state = {
        currentTab: 'today'
    }
    handleTabs = (value) => {
        this.setState({
            currentTab: value
        })
    }
    render() {
        const {currentTab} = this.state;
        return (
            <div>
                <Header page="alerts"/>
                <div className="alerts">
                    <div className="main">
                        <div className="top">
                            <div 
                                className={`tab ${currentTab === 'today'?'active':''}`}
                                onClick={() => this.handleTabs('today')}>
                                Today
                            </div>
                            <div 
                                className={`tab ${currentTab === 'week'?'active':''}`}
                                onClick={() => this.handleTabs('week')}>
                                This Week
                            </div>
                            <div 
                                className={`tab ${currentTab === 'month'?'active':''}`}
                                onClick={() => this.handleTabs('month')}>
                                This Month
                            </div>
                            <div 
                                className={`tab ${currentTab === 'all'?'active':''}`}
                                onClick={() => this.handleTabs('all')}>
                                All
                            </div>
                        </div>
                        <div className="alerts-content">
                            <div className="date">Today, December 11 2018</div>
                            <div className="alert">
                                <div 
                                    className="icon"
                                    style={{backgroundColor:'rgba(152, 138, 182, 0.1)',color:'#988AB6'}}>
                                    <i className="fa fa-users"></i>
                                </div>
                                <div className="item">
                                    <h6>Increase in total number of Enrollees. <span>Dec 11</span></h6>
                                    <h5>Average number of enrollees increased to <span className="change">38 from 7 yesterday (31%)</span></h5>
                                </div>
                            </div>
                            <div className="alert">
                                <div 
                                    className="icon"
                                    style={{backgroundColor:'rgba(96, 115, 126, 0.1)',color:'#60737E'}}>
                                    <i className="fa fa-map-marker"></i>
                                </div>
                                <div className="item">
                                    <h6>Change in Risk Weight. <span>Dec 11</span></h6>
                                    <h5>Risk Weight for Ojo LGA increased <span className="change">from 1 to 3</span></h5>
                                </div>
                            </div>
                            <div className="alert">
                                <div 
                                    className="icon"
                                    style={{backgroundColor:'rgba(152, 138, 182, 0.1)',color:'#988AB6'}}>
                                    <i className="fa fa-users"></i>
                                </div>
                                <div className="item">
                                    <h6>Increase in number of Enrollees. <span>Dec 11</span></h6>
                                    <h5>Average number of enrollees in Amuwo-Odofin LGA increased to <span className="change">38 from 7 yesterday (31%)</span></h5>
                                </div>
                            </div>
                            <div className="alert">
                                <div 
                                    className="icon"
                                    style={{backgroundColor:'rgba(144, 108, 95, 0.1)',color:'#906C5F'}}>
                                    <i className="fa fa-graduation-cap"></i>
                                </div>
                                <div className="item">
                                    <h6>Increase in total number of School Aged Enrollees. <span>Dec 11</span></h6>
                                    <h5>Number of school aged enrollees increased to <span className="change">180 from 100 yesterday (20%) </span></h5>
                                </div>
                            </div>
                            <div className="alert">
                                <div 
                                    className="icon"
                                    style={{backgroundColor:'rgba(56, 73, 73, 0.1)',color:'#384949'}}>
                                    <i className="fa fa-refresh"></i>
                                </div>
                                <div className="item">
                                    <h6>Repeat case of an Enrollee. <span>Dec 11</span></h6>
                                    <h5>One repeat case was detected today</h5>
                                </div>
                                <div className="btn-div">
                                    <button>View Enrollee</button>
                                </div>
                            </div>
                            {
                                currentTab === 'week' &&
                                <React.Fragment>
                                    <div className="date">Monday, December 10 2018</div>
                                    <div className="alert">
                                        <div
                                            className="icon"
                                            style={{ backgroundColor: 'rgba(152, 138, 182, 0.1)', color: '#988AB6' }}>
                                            <i className="fa fa-users"></i>
                                        </div>
                                        <div className="item">
                                            <h6>Increase in total number of Enrollees. <span>Dec 11</span></h6>
                                            <h5>Average number of enrollees increased to <span className="change">38 from 7 yesterday (31%)</span></h5>
                                        </div>
                                    </div>
                                    <div className="alert">
                                        <div
                                            className="icon"
                                            style={{ backgroundColor: 'rgba(96, 115, 126, 0.1)', color: '#60737E' }}>
                                            <i className="fa fa-map-marker"></i>
                                        </div>
                                        <div className="item">
                                            <h6>Change in Risk Weight. <span>Dec 11</span></h6>
                                            <h5>Risk Weight for Ojo LGA increased <span className="change">from 1 to 3</span></h5>
                                        </div>
                                    </div>
                                    <div className="alert">
                                        <div
                                            className="icon"
                                            style={{ backgroundColor: 'rgba(152, 138, 182, 0.1)', color: '#988AB6' }}>
                                            <i className="fa fa-users"></i>
                                        </div>
                                        <div className="item">
                                            <h6>Increase in number of Enrollees. <span>Dec 11</span></h6>
                                            <h5>Average number of enrollees in Amuwo-Odofin LGA increased to <span className="change">38 from 7 yesterday (31%)</span></h5>
                                        </div>
                                    </div>
                                    <div className="alert">
                                        <div
                                            className="icon"
                                            style={{ backgroundColor: 'rgba(144, 108, 95, 0.1)', color: '#906C5F' }}>
                                            <i className="fa fa-graduation-cap"></i>
                                        </div>
                                        <div className="item">
                                            <h6>Increase in total number of School Aged Enrollees. <span>Dec 11</span></h6>
                                            <h5>Number of school aged enrollees increased to <span className="change">180 from 100 yesterday (20%) </span></h5>
                                        </div>
                                    </div>
                                    <div className="alert">
                                        <div
                                            className="icon"
                                            style={{ backgroundColor: 'rgba(56, 73, 73, 0.1)', color: '#384949' }}>
                                            <i className="fa fa-refresh"></i>
                                        </div>
                                        <div className="item">
                                            <h6>Repeat case of an Enrollee. <span>Dec 11</span></h6>
                                            <h5>One repeat case was detected today</h5>
                                        </div>
                                        <div className="btn-div">
                                            <button>View Enrollee</button>
                                        </div>
                                    </div>
                                </React.Fragment>
                            }
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Alerts;
